import React, { useRef } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { exportCSV, exportExcel,  toDecimal } from "../Common/Common";

function FinanceTable({ summary, title,  val, closeHandler }) {
  const dt = useRef(null);
  const bodyTemplate= (data, col)=> {   return data[col]?data[col]:""}

  const sumAllMonth = (data) => {
    return '$' + data.reduce((prev, next) => parseFloat(prev) + parseFloat(next)).toFixed(2)
  }
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.January ? item.January : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.February ? item.February : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.March ? item.March : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.April ? item.April : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.May ? item.May : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.June ? item.June : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.July ? item.July : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.August ? item.August : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.September ? item.September : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.October ? item.October : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.November ? item.November : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.December ? item.December : 0))} />
        <Column align='right' footer={summary && sumAllMonth(summary.map(item => item.Total ? item.Total : 0))} />

      </Row>
    </ColumnGroup>
  );

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <Tooltip position='bottom' target=".amount_expected" content={summary && sumAllMonth(summary.map(item => item.Total ? item.Total : 0))} />
      <span className="text-xl text-900 font-bold amount_expected">{title + ": " + toDecimal(val, true)}</span>
      <div>
        <Tooltip target="button" position="bottom" />
        {summary && summary.length > 0 && <>
          <Button size="small" type="button" icon="pi pi-download" className="csv m-1" onClick={() => exportCSV(dt, false)} label="CSV" />
          <Button
            type="button"
            size="small"
            className="xls m-1"
            icon="pi pi-download"

            onClick={() => exportExcel(title, summary)}
            label="XLS"
          />
          <Button type="reset" size="small" icon="pi pi-arrow-left" style={{ height: '36px', }} className="xls m-1" label="Back" onClick={() => {
          closeHandler()
          }} />
        </>}

      </div>
    </div>
  );
  return (
    <div>
      {summary && summary.length > 0 ?
        <DataTable showGridlines stripedRows  ref={dt} exportFilename={title + "_export_" + new Date().getTime()} value={summary.map(x => {
          return {
            Code: x.Code, January: '$' + x.January.toFixed(2), February: '$' + x.February.toFixed(2), March: '$' + x.March.toFixed(2), April: '$' + x.April.toFixed(2), May: '$' + x.May.toFixed(2), June: '$' + x.June.toFixed(2), July: '$' + x.July.toFixed(2), August: '$' + x.August.toFixed(2)
            , September: '$' + x.September.toFixed(2), October: '$' + x.October.toFixed(2), November: '$' + x.November.toFixed(2), December: '$' + x.December.toFixed(2), Total: '$' + x.Total.toFixed(2)
          }
        })} footerColumnGroup={footerGroup} resizableColumns={true} removableSort paginator rows={200} rowsPerPageOptions={[100,  200,500,1000]} header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"  className="financeDatatable">
          {Object.keys(summary[0]).map((col, index) => {
            return <Column align={index === 0 ? 'left' : 'right'} field={col} key={index} body={(e) => {
              return bodyTemplate(e, col);
            }} header={col==="Code"?"CPT":col} sortable />;

          })}
        </DataTable> :
        <div className="card flex justify-content-center">
          <ProgressSpinner /></div>}
    </div>
  )
}

export default FinanceTable