import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
function AddEmployment({ saveHandler, toast, closeHandler }) {
  //   const defaultValues = {
  //     provider: undefined,
  //     email: undefined,
  //     hours_per_week: undefined,
  //     start_date: undefined,
  //     mon_hr: 8,
  //     tue_hr: 8,
  //     wed_hr: 8,
  //     thu_hr: 8,
  //     fri_hr: 8,
  //     sat_hr: 0,
  //     sun_hr: 0,
  //   };
  const [formData, setFormData] = useState({
    provider: undefined,
    email: undefined,
    hours_per_week: undefined,
    start_date: undefined,
    mon_hr: 8,
    tue_hr: 8,
    wed_hr: 8,
    thu_hr: 8,
    fri_hr: 8,
    sat_hr: 0,
    sun_hr: 0,
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: formData });
  const onSubmit = (data) => {
    let daily_sum =
      parseFloat(data.mon_hr) +
      parseFloat(data.tue_hr) +
      parseFloat(data.wed_hr) +
      parseFloat(data.thu_hr) +
      parseFloat(data.fri_hr) +
      parseFloat(data.sat_hr) +
      parseFloat(data.sun_hr);
    if (data.hours_per_week == daily_sum) {
      saveHandler(data);
    } else {
      toast?.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Hours per Week and Sum of Work schedule not matching",
        life: 3000,
      });
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  useEffect(() => {
    // Set form values when formData is updated
    for (const key in formData) {
      setValue(key, formData[key]);
    }
  }, [formData, setValue]);
  const updateDailyHrs = (e) => {
    let weeklyHrs = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      mon_hr: parseFloat(weeklyHrs / 5),
      tue_hr: parseFloat(weeklyHrs / 5),
      wed_hr: parseFloat(weeklyHrs / 5),
      thu_hr: parseFloat(weeklyHrs / 5),
      fri_hr: parseFloat(weeklyHrs / 5),
      hours_per_week: weeklyHrs,
    }));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className=" p-fluid">
        <div className="flex mt-5">
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="provider"
                control={control}
                rules={{ required: "Provider is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        provider: e.target.value,
                      }));
                    }}
                  />
                )}
              />
              <label
                htmlFor="provider"
                className={classNames({ "p-error": errors.name })}
              >
                Provider *
              </label>
            </span>
            {getFormErrorMessage("provider")}
          </div>
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="text"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                  />
                )}
              />
              <label
                htmlFor="provider"
                className={classNames({ "p-error": errors.name })}
              >
                Email *
              </label>
            </span>
            {getFormErrorMessage("email")}
          </div>
        </div>
        <div className="flex mt-5">
          <div className="field  m-1">
            <span className="p-float-label">
              <Controller
                name="hours_per_week"
                control={control}
                rules={{
                  required: "Hours Per Week  is required.",
                  max: {
                    value: 40,
                    message: "must be less than or equal 40",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    onChange={(e) => {
                      updateDailyHrs(e);
                    }}
                  />
                )}
              />
              <label
                htmlFor="hours_per_week"
                className={classNames({ "p-error": errors.name })}
              >
                Hours Per Week *
              </label>
            </span>
            {getFormErrorMessage("hours_per_week")}
          </div>
          <div className="field  m-1">
            <span className="p-float-label">
              <Controller
                name="start_date"
                control={control}
                rules={{ required: "Start Date is required." }}
                render={({ field, fieldState }) => (
                  <Calendar
                    dateFormat="mm/dd/yy"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        start_date: e.target.value,
                      }));
                    }}
                  />
                )}
              />
              <label
                htmlFor="start_date"
                className={classNames({ "p-error": errors.name })}
              >
                Start Date *
              </label>
            </span>
            {getFormErrorMessage("start_date")}
          </div>
        </div>

        <label className="flex pt-5 pb-5">
          <b>Work Schedule</b>
        </label>
        <div className="grid">
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="mon_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    max={12}
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="mon_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Monday
              </label>
            </span>
            {getFormErrorMessage("mon_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="tue_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="tue_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Tuesday
              </label>
            </span>
            {getFormErrorMessage("tue_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="wed_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="wed_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Wednesday
              </label>
            </span>
            {getFormErrorMessage("wed_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="thu_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="thu_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Thursday
              </label>
            </span>
            {getFormErrorMessage("thu_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="fri_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="fri_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Friday
              </label>
            </span>
            {getFormErrorMessage("fri_hr")}
          </div>
        </div>
        <div className="grid">
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="sat_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="sat_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Saturday
              </label>
            </span>
            {getFormErrorMessage("sat_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="sun_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="sun_hr"
                className={classNames({ "p-error": errors.name })}
              >
                Sunday
              </label>
            </span>
            {getFormErrorMessage("sun_hr")}
          </div>
        </div>

        <div className="grid">
          <div className="col-3"></div>
          <div className="col-3">
            <Button
              type="button"
              label="Cancel"
              className="mt-2"
              onClick={closeHandler}
              outlined
            />
          </div>
          <div className="col-3">
            <Button type="submit" label="Submit" className="mt-2" />
          </div>
          <div className="col-3"></div>
        </div>
      </form>
    </>
  );
}
export default AddEmployment;
