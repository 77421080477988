import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from 'primereact/fileupload';
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import moment from "moment";
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddToolTime from "./AddToolTime";

const FormData = require('form-data');

function TimeTool() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const dt = useRef(null);
  let [tooltime, setTooltime] = useState([])
  const [upload, setUpload] = useState([])
  const [loading, setLoading] = useState(false)
  const toast = useRef();
  const [filters] = useState({
    provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
    known_as: { value: null, matchMode: FilterMatchMode.CONTAINS },
    day: { value: null, matchMode: FilterMatchMode.CONTAINS },
    time: { value: null, matchMode: FilterMatchMode.CONTAINS },

  });
  useEffect(() => {
    getTooltime();
  }, [])
  const getTooltime = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}tooltime`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setTooltime(response.data.data);
          setUpload(response.data.datahis);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      })
  }
  const insertToolTime = (e) => {    
    let data = new FormData();
    data.append('stream', e.file);
    data.append('tooltimedate',new Date(e.date).toDateString());
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}inserttooltime`, data)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getTooltime();
          setPopupVisible(false);

          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });

  }


  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Tool Time</span>
      <div style={{ display: 'flex' }}>
      <Button  className="ml-3" label="Upload Tool Time CSV" icon="pi pi-plus" type="button" onClick={ ()=>{ setPopupVisible(true)}}></Button>
      
          <Button  className="ml-3" label="Sample File" icon="pi pi-download" type="button" onClick={async ()=>{
             const link = document.createElement('a');
             link.href = `${process.env.REACT_APP_API_KEY}tooltimedownload/time tool.csv`;
             document.body.appendChild(link);
             link.click();
             await sleep(200);
             link.parentNode.removeChild(link);
          }}></Button>
      </div>
    </div>
  );
  const header1 = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Upload History</span>
    </div>
  );
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const deleteToolTime = (value) => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_KEY}deleteToolTime/${value}`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getTooltime();         
          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  }
  const confirm = (e) => {
    confirmDialog({
        message: `Do you want to delete ${e.file_name} Tool Time file?`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-danger',
        accept:()=>{deleteToolTime(e.created_date)},
    });
};
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Dialog 
      visible={isPopupVisible}
      onHide={() => setPopupVisible(false)}
      header="Upload Tool Time"
    >
      <AddToolTime loading={loading} saveHandler={insertToolTime} closeHandler={()=>{setPopupVisible(!isPopupVisible)}} />
    </Dialog>
      <DataTable filters={filters} loading={loading} filterDisplay="row" showGridlines stripedRows ref={dt} exportFilename={"employment_" + new Date().getTime()} value={tooltime} resizableColumns={true} removableSort paginator rows={200} rowsPerPageOptions={[100, 200, 500, 1000]} header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" className="financeDatatable">
        <Column field="provider" header="Provider ID" filter filterPlaceholder="Search by Provider ID" sortable></Column>
        <Column field="known_as" header="Provider Name" filter filterPlaceholder="Search by Provider Name" sortable></Column>
        <Column field="day" header="Tool Time Date (MM/DD/YYYY)" filter filterPlaceholder="Search by Tool Time"  sortable></Column>
         {/* <Column field="day" header="Last Visit (All Apps)(MM/DD/YYYY HH:mm:ss)" body={(e) => {
          return e.lastvisit && moment(e.lastvisit).format("MM/DD/YYYY HH:mm:ss")
        }} sortable></Column> */}
        <Column field="time" header="Time (Minutes)" filter filterPlaceholder="Search by Time" sortable></Column>
        <Column field="created_date" header="Created Date" body={(e) => {
          return e.created_date && moment(new Date(e.created_date*1).toGMTString()).format("MM/DD/YYYY HH:mm:ss")
        }} sortable></Column>
      </DataTable>
      <DataTable header={header1} loading={loading} value={upload} showGridlines stripedRows paginator rows={200} rowsPerPageOptions={[100, 200, 500, 1000]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }} currentPageReportTemplate="{first} to {last} of {totalRecords}" className="financeDatatable mt-3" >
        {/* <Column field="date" header="Date" body={(e) => {
          return e.date && moment(e.date).format("MM/DD/YYYY")
        }} sortable></Column> */}
        <Column field="file_name" header="FileName" sortable></Column>
        <Column field="created_date" header="Created Date" body={(e) => {
          return e.created_date && moment(new Date(e.created_date*1).toGMTString()).format("MM/DD/YYYY HH:mm:ss").toString()
        }} sortable></Column>
        <Column field="date" header="Tool Time Date (MM/DD/YYYY)" body={(e) => {
          return e.date 
        }} sortable></Column>
        <Column field="action" header="Action" body={(e) => {
          return <><i className="pi pi-download" onClick={async () => {
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_API_KEY}pmtdownload/` + e.file;
            document.body.appendChild(link);
            link.click();
            await sleep(200);
            link.parentNode.removeChild(link);

          } }></i>
          <i style={{ color: 'red' }} className="mt-1 ml-2 pi pi-trash" onClick={() => {
            confirm(e);
          } }></i></>
        }} ></Column>

      </DataTable>
    </>
  )
}
export default TimeTool;