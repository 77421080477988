/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../src/theaming/theme.css";
import Header from "./Common/Header";
import Kpi from "./Kpi/Kpi";
import { HashRouter, Route, Routes } from "react-router-dom";
import FinanceDashboard from "./Finance/FinanceDashboard";
import PMT from "./PMT/pmt";
import Login from "./Login";
import axios from "axios";
function App() {
  const [token, setToken] = useState(
    sessionStorage.getItem("token") ? sessionStorage.getItem("token") : null
  );
  axios.interceptors.request.use(
    function (config) {
      const Token = sessionStorage.getItem("token");
      if (
        Token &&
        config.url.split(".")[config.url.split(".").length - 1] !== "login"
      ) {
        config["headers"] = {
          Authorization: "bearer " + Token,
        };
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.request.status === 400 || error.request.status === 401) {
        setToken(null);
        sessionStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <HashRouter>
        <Header setToken={setToken} token={token} />
        <Routes>
          {!token ? (
            <Route path="/*" element={<Login setToken={setToken} />} />
          ) : (
            <>
              <Route path="/" element={<Kpi />} />
              <Route path="/kpi" element={<Kpi />} />
              <Route path="/finance" element={<FinanceDashboard />} />
              <Route path="/pmt" element={<PMT />} />
            </>
          )}
        </Routes>
      </HashRouter>
    </div>
  );
}
export default App;
