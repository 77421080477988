/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
//KPI and KPI Trends
export default function KpiTrends({keyId,title1, fdata, visible, closeHandler }) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const footerContent = (
    <div>
      <Button label="Cancel" onClick={() => { closeHandler() }} className="p-button-text" />
      <Button label="Ok" onClick={() => { closeHandler() }} autoFocus />
    </div>
  );
  useEffect(() => {
    kpiCall((kpi) => {
   setChartData({
          
    series: [{
        name: "KPI Value",
        data: kpi.map(x=>x.kpi_value)
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'KPI Trends by last 6 Month',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], 
          opacity: 0.5
        },
      },
      xaxis: {
        categories:kpi.map(x=>x.date),
      },
      yaxis:{
        min:0,
        max:100,
        tickAmount:5,

      }
    }  
  
  })
       
    })
  }, []);



  const kpiCall = (callback) => {
    setLoading(true);
     fdata={
      ...fdata, primary_payer: fdata.primary_payer ? fdata.primary_payer : '',
      member_advocate: fdata.member_advocate ? Number(fdata.member_advocate) : '',
      chronic_care_specialist: fdata.chronic_care_specialist ? Number(fdata.chronic_care_specialist) : '',
      supervising_provider: fdata.supervising_provider ? Number(fdata.supervising_provider) : '',kpiId:keyId
    }
    axios.post(`${process.env.REACT_APP_API_KEY}kpitrends`,fdata )
      .then(function (response) {
        if (response.data.success) {
          setLoading(false);
          callback(response.data.data);
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {
      });
  }
  return (
   
      <Dialog
        header={title1}
        visible={visible}
        style={{ width: '80vw', height: '80vh', }}
        onHide={() => { closeHandler() }}
        footer={footerContent}
      >
        <div className="m-0">
          {loading ? <div className="card flex justify-content-center">
            <ProgressSpinner /></div> :
            <div className="card">
                <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
            </div>}
        </div>
      </Dialog>

  );
}