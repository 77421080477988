/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { TabView, TabPanel } from "primereact/tabview";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import { Skeleton } from 'primereact/skeleton';
import { MultiSelect } from 'primereact/multiselect';
import axios from "axios";
import KpiNewPage from './KpiNewPage'
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "./../../src/theaming/theme.css"
function Kpi() {
  const toast = useRef(null);
  const [kpi, setKpi] = useState([]);
  const [keyId, setkeyId] = useState()
  // kpi filter data
  const [data, setdata] = useState({
    pid: undefined,
    organization: [],
    state: [],
    primary_payer: undefined,
    member_advocate: undefined,
    chronic_care_specialist: undefined,
    supervising_provider: undefined
  });
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("p");
  const [checked, setChecked] = useState(true);
  const [member, setmemberDropdownFilter] = useState([]);
  const [organization, setorganizationDropdownFilter] = useState([]);
  const [state, setstateDropdownFilter] = useState([]);
  const [member_advocate, setmember_advocateDropdownFilter] = useState([]);
  const [chronic_care_specialist, setchronic_care_specialistDropdownFilter] = useState([]);
  const [supervising_provider, setsupervising_providerDropdownFilter] = useState([]);
  const [primary_payer, setprimary_payerDropdownFilter] = useState([]);
  const items = [
    { name: "Whole Person Care Planning", code: "A" },
    { name: "Preventative Care", code: "B" },
    { name: "Member Engagement", code: "C" },
    { name: "Medication Adherence and Management", code: "D" },
    { name: "Health Monitoring and Self Recovery", code: "E" },
    { name: "Care Escalation and Integration", code: "F" },
    { name: "MIPS", code: "M" },

  ];
  const [title1, setTitle] = useState();
  const [selectedMenu, setSelectedMenu] = useState(items[0].code);
  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_KEY}dropdown`)
      .then(async function (response) {
        if (response.data.success) {
          setorganizationDropdownFilter(response.data.data.filter(x => x.type === "organization"))
          setmemberDropdownFilter(response.data.data.filter(x => x.type === "pid"))
          setmember_advocateDropdownFilter(response.data.data.filter(x => x.type === "member_advocate"))
          setchronic_care_specialistDropdownFilter(response.data.data.filter(x => x.type === "chronic_care_specialist"))
          setsupervising_providerDropdownFilter(response.data.data.filter(x => x.type === "supervising_provider"))
          setprimary_payerDropdownFilter(response.data.data.filter(x => x.type === "primary_payer"))
          setstateDropdownFilter(response.data.data.filter(x => x.type === "state"))
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {
      })

  }, []);
  useEffect(() => {
    // axios call for getting KPI summary
    kpiCall(data)
  }, []);
  const kpiCall = (fdata) => {
    setLoading(true);
    fdata = {
      ...fdata, primary_payer: fdata.primary_payer ? fdata.primary_payer : '',
      member_advocate: fdata.member_advocate ? Number(fdata.member_advocate) : '',
      chronic_care_specialist: fdata.chronic_care_specialist ? Number(fdata.chronic_care_specialist) : '',
      supervising_provider: fdata.supervising_provider ? Number(fdata.supervising_provider) : ''
    }
    axios.post(`${process.env.REACT_APP_API_KEY}kpi`, fdata)
      .then(function (response) {
        if (response.data.success) {
          setLoading(false);
          setKpi(response.data.data);
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {
      });
  }
  const [details, setDetails] = useState([]);
  const getDetails = (fdata) => {
    setLoading(true);
    fdata = {
      ...fdata, primary_payer: fdata.primary_payer ? fdata.primary_payer : '',
      member_advocate: fdata.member_advocate ? Number(fdata.member_advocate) : '',
      pid: fdata.pid ? Number(fdata.pid) : 0,
      chronic_care_specialist: fdata.chronic_care_specialist ? Number(fdata.chronic_care_specialist) : '',
      supervising_provider: fdata.supervising_provider ? Number(fdata.supervising_provider) : ''
    }
    //axios call for getting KPI details
    axios.post(`${process.env.REACT_APP_API_KEY}kpibykpiId`, fdata)
      .then(function (response) {
        if (response.data.success) {
          setLoading(false);
          setDetails(response.data.data);
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        // handle error
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });

      })
      .finally(function () {
        // always executed
      });

  };
  const justifyOptions = [
    { icon: "pi pi-percentage", value: "p1" },
    { icon: "pi pi-credit-card", value: "c" },
    { icon: "pi pi-chart-pie", value: "p" },
    { icon: "pi pi-chart-bar", value: "g" },
  ];
  const justifyTemplate = (option) => {
    return <i className={option.icon}></i>;
  };
  const sk = () => {
    return <div className="grid">
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
      <div className="col-12 md:col-6 lg:col-4 " ><Skeleton className="mb-2" height="200px"></Skeleton></div>
    </div>
  }
  // To display dropdown selected value
  const selectedDropdownTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const panelFooterTemplate = (data) => {
    const length = data ? data.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? 's' : ''} selected.
      </div>
    );
  };
  //To display dropdown options 
  const DropdownOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  return (
    <div className=" grid flex gap-3 py-4 px-2">
      <div className="card col-12 md:col-3 lg:col-2 flex-grow-1 flex-shrink-1 " style={{ minHeight: "80vh", padding: "1.5rem" }}>
        <div >
          {keyId && <div className=" mb-2">
            <label className="text-lg pl-1 text-700 font-bold" >Member</label>
            <Dropdown value={data.pid} showFilterClear showClear onChange={(e) => { setdata({ ...data, pid: e.value }); }} options={member.map(item => { return { name: item.id + "-" + item.name.trim(), code: item.id } })} optionValue="code" optionLabel="name" placeholder=" Select Member" filter valueTemplate={selectedDropdownTemplate} itemTemplate={DropdownOptionTemplate} className="w-full mt-2 text-overflow-ellipsis" />
          </div>}
          <div className="my-2">
            <label className="text-lg pl-1 text-700 font-bold">State</label>
            <MultiSelect value={data.state} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(data.state)} onChange={(e) => { setdata({ ...data, state:  e.value?e.value:[] }); }} display="chip" options={state} optionValue="id" optionLabel="name" placeholder=" Select State" filter maxSelectedLabels={10} className="w-full mt-2 multiselectCss" />
          </div>
          <div className="my-2">
            <label className="text-lg pl-1 text-700 font-bold">Organization Group</label>
            <MultiSelect value={data.organization_group} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(data.organization_group)} onChange={(e) => {

              const organization_group = e.value;
              let _organization = [];
              if (organization_group)
                organization_group.forEach(y => {
                  if (y === 'Mass Retirees') {
                    _organization = [..._organization, ...organization.filter(x => x.name.includes('MIIA'))];
                    _organization = [..._organization, ...organization.filter(x => x.name.includes('Mass Retirees'))];
                    _organization = [..._organization, ...organization.filter(x => x.name.includes('Unicare'))]
                  } else {
                    _organization = [..._organization, ...organization.filter(x => x.name.includes(y))]
                  }

                });
              setdata({ ...data, organization_group: organization_group, organization: _organization.map((item) => item.name) });
            }} display="chip" options={[{ name: 'MIIA', code: 'MIIA' }, { name: 'Mass Retirees', code: 'Mass Retirees' }, { name: 'Housing', code: 'Housing' }]} optionValue="name" optionLabel="name" placeholder=" Select Organization Group" filter maxSelectedLabels={10} className="w-full mt-2 multiselectCss" />
          </div>
          <div className="my-2">
            <label className="text-lg pl-1 text-700 font-bold">Organization</label>
            <MultiSelect value={data.organization} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(data.organization)} onChange={(e) => { setdata({ ...data, organization: e.value?e.value:[] }); }} display="chip" options={organization} optionValue="name" optionLabel="name" placeholder=" Select Organization" filter maxSelectedLabels={10} className="w-full mt-2 multiselectCss" />
          </div>
          <div className=" my-2">
            <label className="text-lg pl-1 text-700 font-bold" >Primary Payer</label>
            <Dropdown value={data.primary_payer} showFilterClear showClear onChange={(e) => { setdata({ ...data, primary_payer: e.value }); }} options={primary_payer} optionValue="id" optionLabel="name" placeholder=" Select Primary Payer" filter valueTemplate={selectedDropdownTemplate} itemTemplate={DropdownOptionTemplate} className="w-full mt-2" />
          </div>
          <div className=" my-2">
            <label className="text-lg pl-1 text-700 font-bold"  >Member Advocate</label>
            <Dropdown value={data.member_advocate} showFilterClear showClear onChange={(e) => { setdata({ ...data, member_advocate: e.value }); }} options={member_advocate.map(item => { return { name: item.id + "-" + item.name.trim(), code: item.id } })} optionValue="code" optionLabel="name" placeholder=" Select Member Advocate" filter valueTemplate={selectedDropdownTemplate} itemTemplate={DropdownOptionTemplate} className="w-full mt-2" />
          </div>
          <div className=" my-2">
            <label className="text-lg pl-1 text-700 font-bold">Chronic Care Specialist</label>
            <Dropdown value={data.chronic_care_specialist} showFilterClear showClear onChange={(e) => { setdata({ ...data, chronic_care_specialist: e.value }); }} options={chronic_care_specialist.map(item => { return { name: item.id + "-" + item.name.trim(), code: item.id } })} optionValue="code" optionLabel="name" placeholder=" Select Chronic Care Specialist" filter valueTemplate={selectedDropdownTemplate} itemTemplate={DropdownOptionTemplate} className="w-full mt-2" />
          </div>
          <div className=" my-2" >
            <label className="text-lg pl-1 text-700 font-bold">Supervising Provider</label>
            <Dropdown value={data.supervising_provider} showFilterClear showClear onChange={(e) => { setdata({ ...data, supervising_provider: e.value }); }} options={supervising_provider.map(item => { return { name: item.id + "-" + item.name.trim(), code: item.id } })} optionValue="code" optionLabel="name" placeholder=" Select Supervising Provider" filter valueTemplate={selectedDropdownTemplate} itemTemplate={DropdownOptionTemplate} className="w-full mt-2 mb-5" />
          </div>

          <div className="grid">
            <div className="m-0 col-6">
              <Button type="reset" label="Reset" severity="secondary" className="w-full" onClick={() => {
                let filterData = {
                  pid: undefined,
                  organization: [],
                  state: [],
                  primary_payer: undefined,
                  member_advocate: undefined,
                  chronic_care_specialist: undefined,
                  supervising_provider: undefined
                }
                setdata(filterData);
                setTimeout(() => {
                  if (keyId)
                    getDetails({ ...filterData, kpiId: keyId });
                  else
                    kpiCall(filterData);
                }, 100);
              }} />
            </div>
            <div className="m-0 col-6">
              <Button label="Apply" onClick={() => {
                if (keyId)
                  getDetails({ ...data, kpiId: keyId });
                else
                  kpiCall(data)
              }} className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="card col-12 md:col-8 lg:col-9 flex flex-column flex-shrink-0 " style={{ minHeight: "80vh" }}>
        <Toast ref={toast} />
        <div className="flex flex-wrap flex-shrink-0 justify-content-between">
          <div className="flex flex-wrap flex-shrink-0 justify-content-start gap-3 mb-4">{
            checked && !keyId && <Dropdown value={selectedMenu}
              onChange={(e) => {
                setSelectedMenu(e.value);
              }}
              options={items}
              optionLabel="name"
              optionValue="code"
              placeholder="Select a Menu" className="w-full md:w-18rem" />
          }</div>
          <div className="flex flex-wrap flex-shrink-0 justify-content-end gap-3 mb-4">
            {!keyId && <><SelectButton
              value={type}
              onChange={(e) => {
                e.value && setType(e.value);
              }}
              itemTemplate={justifyTemplate}
              optionLabel="value"
              options={justifyOptions} /><ToggleButton style={{ padding: '0.50rem 1.25rem' }} onLabel="Tab" offLabel="Dropdown" checked={checked} onChange={(e) => setChecked(e.value)} /></>}
          </div>
        </div>
        <div className="flex-shrink-0" >
          {!checked && !keyId &&
            <TabView scrollable={true} onTabChange={(e) => {

              setSelectedMenu(items[e.index].code)
            }} activeIndex={items.findIndex(val => val.code === selectedMenu)}>
              {items.map(a => {
                return <TabPanel key={a.code} header={a.name} leftIcon="pi pi-calendar mr-2">
                </TabPanel>
              })}
            </TabView>}
          <div className="grid">
            <div className={"col-12"}>
              {loading ? sk() :
                <KpiNewPage details={details.map(val => {
                  const tempData = { ...val }
                  for (const property in tempData) {
                    if (tempData[property] === "Blank" || tempData[property] === "0-Blank") {
                      tempData[property] = ''
                    }
                  }
                  return tempData
                })} title1={title1} setTitle={setTitle} getDetails={getDetails} Kpi={kpi.filter(x => x.kpi_id.includes(selectedMenu))} member={member} keyId={keyId} setkeyId={setkeyId} data={data} setdata={setdata}
                  organization={organization}
                  member_advocate={member_advocate}
                  chronic_care_specialist={chronic_care_specialist}
                  supervising_provider={supervising_provider}
                  primary_payer={primary_payer} type={type} ></KpiNewPage>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Kpi;