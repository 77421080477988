import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEmployment from "./AddEmployment";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import axios from "axios";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ViewEmployment from "./ViewEmployment";

function Employment() {
  const dt = useRef(null);
  let [employment, setEmployment] = useState([]);
  let [empInfo, setEmpInfo] = useState();
  let [currentemployment, setCurrentEmployment] = useState([]);

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isViewEmpSchedule, setViewEmpSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useRef();
  const [filters] = useState({
    provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    known_as: { value: null, matchMode: FilterMatchMode.CONTAINS },
    start_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  useEffect(() => {
    getEmployment();
  }, []);
  const getEmployment = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}employment`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setEmployment(response.data.data);
          setCurrentEmployment(response.data.currentdata);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const deleteEmployment = (values) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}deleteEmployment`, values)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getEmployment();

          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const saveEmployment = (values) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}insertemployment`, {
        ...values,
        start_date: new Date(values.start_date).toDateString(),
      })
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getEmployment();

          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
          setPopupVisible(false);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const viewEmployeeSchedule = (values) => {
    setEmpInfo(values);
    setViewEmpSchedule(true);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Employment</span>
      <div>
        <Tooltip target="button" position="bottom" />
        <Button
          label="Download Current"
          className="csv m-1 mr-4"
          onClick={() => dt.current.exportCSV({ selectionOnly: false })}
          icon="pi pi-download"
        />
        <Button
          label="Add Employment"
          className="csv m-1"
          onClick={() => setPopupVisible(true)}
          icon="pi pi-plus"
        />
      </div>
    </div>
  );
  const confirm = (e) => {
    confirmDialog({
      message: `Do you want to delete this Employment (${e.provider})?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        deleteEmployment({
          ...e,
          created_date: e.created_date,
          start_date: e.start_date && moment(e.start_date),
        });
      },
    });
  };
  return (
    <>
      <Dialog
        visible={isPopupVisible}
        onHide={() => setPopupVisible(false)}
        header="Add Employment and Work Schedule"
      >
        <AddEmployment
          saveHandler={saveEmployment}
          toast={toast}
          closeHandler={() => {
            setPopupVisible(!isPopupVisible);
          }}
        />
      </Dialog>
      <Dialog
        visible={isViewEmpSchedule}
        onHide={() => setViewEmpSchedule(false)}
        header="Employment and Work Schedule"
      >
        <ViewEmployment
          data={empInfo}
          toast={toast}
          closeHandler={() => {
            setViewEmpSchedule(!isViewEmpSchedule);
          }}
        />
      </Dialog>

      <Toast ref={toast} />
      <ConfirmDialog />

      <DataTable
        loading={loading}
        filters={filters}
        filterDisplay="row"
        showGridlines
        stripedRows
        exportFilename={"employment_" + new Date().getTime()}
        value={employment}
        resizableColumns={true}
        removableSort
        paginator
        rows={200}
        rowsPerPageOptions={[100, 200, 500, 1000]}
        header={header}
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "50rem" }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        className="financeDatatable"
      >
        <Column
          field="provider"
          header="Provider ID"
          filter
          filterPlaceholder="Search by Provider ID"
          sortable
        ></Column>
        <Column
          field="known_as"
          header="Provider Name"
          filter
          filterPlaceholder="Search by Provider Name"
          sortable
        ></Column>
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by Provider Email"
          sortable
        ></Column>
        <Column field="hours_per_week" header="Hour Per Week" sortable></Column>
        <Column
          field="status"
          header="Status"
          filter
          filterPlaceholder="Search by Status"
          sortable
        ></Column>
        <Column
          field="start_date"
          header="Start Date"
          sortable
          filter
          filterPlaceholder="Search by Start Date"
        ></Column>

        <Column
          field="created_date"
          header="Created Date"
          body={(e) => {
            return (
              e.created_date &&
              moment(new Date(e.created_date * 1).toGMTString()).format(
                "MM/DD/YYYY HH:mm:ss"
              )
            );
          }}
          sortable
        ></Column>

        <Column
          field="action"
          header="Action"
          body={(e) => {
            return (
              <>
                <i
                  className="ml-2 mr-2 pi pi-eye"
                  onClick={() => {
                    viewEmployeeSchedule(e);
                  }}
                  sortable
                ></i>
                <i
                  style={{ color: "red" }}
                  className="mt-1 pi pi-trash"
                  onClick={() => {
                    confirm(e);
                  }}
                ></i>
              </>
            );
          }}
        ></Column>
      </DataTable>
      <DataTable
        hidden={true}
        ref={dt}
        loading={loading}
        filterDisplay="row"
        showGridlines
        stripedRows
        exportFilename={"employment_" + new Date().getTime()}
        value={currentemployment}
        resizableColumns={true}
        removableSort
        paginator
        rows={200}
        rowsPerPageOptions={[100, 200, 500, 1000]}
        header={header}
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "50rem" }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        className="financeDatatable"
      >
        <Column
          field="provider"
          header="Provider ID"
          filter
          filterPlaceholder="Search by Provider ID"
          sortable
        ></Column>
        <Column
          field="known_as"
          header="Provider Name"
          filter
          filterPlaceholder="Search by Provider Name"
          sortable
        ></Column>
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by Provider Email"
          sortable
        ></Column>
        <Column field="hours_per_week" header="Hour Per Week" sortable></Column>
        <Column field="start_date" header="Start Date" sortable></Column>
      </DataTable>
    </>
  );
}
export default Employment;
