/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./../../src/theaming/theme.css"
import ReactApexChart from "react-apexcharts";
import { Card } from "primereact/card";
import { toDecimal } from "../Common/Common";
// import KpiTrends from "../Kpi/KpiTrends";
// import FinanceTrends from "./FinanceTrends";
function FinanceChat({aging, finance, screen, isTrendsView, setIsTrendsView,setScreenName,aging1 }) {
  const [chartAR, setChartAr] = useState({ options: null, series: [] });
  const [chartDS, setChartDS] = useState({ options: null, series: [] });
  const [chartAE, setChartAE] = useState({ options: null, series: [] });
  const [chartyield, setChartyield] = useState({ options: null, series: [] });
  const [, setChartaging] = useState({ options: null, series: [] });
  const [chartaging1, setChartaging1] = useState({ options: null, series: [] });
  const [embyMonth, setEmbyMonth] = useState({ options: null, series: [] });
  useEffect(() => {
    setEmbyMonth({
      series: [{
        name: "UnAdjudicated",
        data: aging1.map(x => {return x.un_adjudicated })
      },{
        name: "Billing Error",
        data: aging1.map(x => {return x.billing_error })
      }
      ,{
        name: "Demographics Error",
        data: aging1.map(x => {return x.demographics_error })
      },{
        name: "Duplicate",
        data: aging1.map(x => {return x.duplicate })
      },{
        name: "Wrong Payer",
        data: aging1.map(x => {return x.wrong_payer })
      }
      ,{
        name: "Not Covered",
        data: aging1.map(x => {return x.not_covered })
      },{
        name: "Reporting",
        data: aging1.map(x => {return x.reporting })
      },{
        name: "Not Contracted",
        data: aging1.map(x => {return x.not_contracted })
      }
      ,{
        name: "Frequency Limit",
        data: aging1.map(x => {return x.frequency_limit })
      },{
        name: "Needs Attachment",
        data: aging1.map(x => {return x.needs_attachment })
      },{
        name: "Other",
        data: aging1.map(x => {return x.other })
      }
      ,{
        name: "Not Defined",
        data: aging1.map(x => {return x.not_defined })
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              
            }
          },
        },
        colors: ['#e6194b', '#9a6324', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff'],
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            if (val === 0)
              return 0
            else
              return toDecimal(val, true)
          },
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'Zero Payment Reasons',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          title: {
            text: 'Months'
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -12
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:  aging1.map(x => {return x.month_name }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'Amount Expected'
          },
          labels: {
            show: true,
            formatter: (value) => toDecimal(value, true),
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },

        }
      }
    })
    setChartaging1({
      series: [{
        name: "Expected Amount ",
        data: aging.map(x => {return x.amountexpected })
      },{
        name: "Amount Collected ",
        data: aging.map(x => {return x.paidamount })
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              // customIcons: [{
              //   icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
              //   index: 0,
              //   title: 'summary',
              //   class: 'custom-icon',
              //   click: function (chart, options, e) {
              //     setIsTrendsView({ ...isTrendsView,title: "OutstandingAging",  isopen: true,isNew: true })
              //     setScreenName("trenddetails")
              //   }
              // }]
            }
          },
        },
        colors: ['#4c0a8a','#eb5601'],
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            if (val === 0)
              return 0
            else
              return toDecimal(val, true)
          },
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'Aging Report',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          title: {
            text: 'Number of Days'
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -12
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:  aging.map(x => {return x.days }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'Amount Collected'
          },
          labels: {
            show: true,
            formatter: (value) => toDecimal(value, true),
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },

        }
      }
    })
    setChartAr({
      series: [{
        name: "Amount",
        data: finance.ar_trend && finance.ar_trend.map(x => x.amount.toFixed(2))
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              customIcons: [{
                icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                index: 0,
                title: 'summary',
                class: 'custom-icon',
                click: function (chart, options, e) {
                  setIsTrendsView({ ...isTrendsView,title: "A/R Trend for last 6 months", isopen: true,isNew: true })
                  setScreenName("trenddetails")
                }
              }]
            }
          },
        },
        colors: ['#4c0a8a'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            if (val === 0)
              return 0
            else
              return toDecimal(val, true)
          },
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'A/R Trend for last 6 months',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          title: {
            text: 'Months'
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -12
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories: finance.ar_trend && finance.ar_trend.map(x => x.month),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'A/R Amount'
          },
          labels: {
            show: true,
            formatter: (value) => toDecimal(value, true),
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },

        }
      }
    })
    setChartDS({
      series: [{
        name: "Amount",
        data: finance.disallow_trend && finance.disallow_trend.map(x => x.amount.toFixed(2))
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              customIcons: [{
                icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                index: 0,
                title: 'summary',
                class: 'custom-icon',
                click: function (chart, options, e) {
                  setIsTrendsView({ ...isTrendsView,title: "Disallow Trend for last 6 months", isopen: true ,isNew: true})
                  setScreenName("trenddetails")
                }
              }]
            }
          },
        },
        colors: ['#eb5601'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            if (val === 0)
              return 0
            else
              return toDecimal(val, true)
          },
          offsetY: -8,
          offsetX: 5,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'Disallow Trend for last 6 months',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -7
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          title: {
            text: 'Months',

          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories: finance.disallow_trend && finance.disallow_trend.map(x => x.month),

        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
            // offsetY: 5
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'Disallow Amount'
          },
          labels: {
            show: true,
            formatter: (value) => toDecimal(value, true),
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },

          }
        }
      },

    })
    setChartAE({
      series: [{
        name: "Amount",
        data: finance.amount_expected && finance.amount_expected.map(x => x.amount.toFixed(2))
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              customIcons: [{
                icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                index: 0,
                title: 'summary',
                class: 'custom-icon',
                click: function (chart, options, e) {
                  setIsTrendsView({ ...isTrendsView,title: "Amount Expected", isopen: true ,isNew: true})
                  setScreenName("trenddetails")
                }
              }]
            }
          },
        },
        colors: ['#eb5601'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            if (val === 0)
              return 0
            else
              return toDecimal(val, true)
          },
          offsetY: -8,
          offsetX: 5,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'Amount Expected',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -7
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          title: {
            text: 'Months',

          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories: finance.amount_expected && finance.amount_expected.map(x => x.month),

        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
            // offsetY: 5
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'Amount Expected'
          },
          labels: {
            show: true,
            formatter: (value) => toDecimal(value, true),
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },

          }
        }
      },

    })
    setChartyield({
      series: [{
        name: "Amount",
        data: finance.ar_yield && finance.ar_yield.map(x => x.amount.toFixed(2))
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              customIcons: [{
                icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                index: 0,
                title: 'summary',
                class: 'custom-icon',
                click: function (chart, options, e) {
                  setIsTrendsView({ ...isTrendsView,title: 'Yield Trend for last 6 months',  isopen: true ,isNew: true})
                  setScreenName("trenddetails")
                }
              }]
            }
          },
        },

        colors: ['#edc641'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return typeof (val) !== "string" && val.toFixed(2) + " %"
          },
          offsetY: -8,
          offsetX: 6,
          style: {
            colors: ['#000'],
            fontWeight: 'bold',
            fontSize: '12px',
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          text: 'Yield Trend for last 6 months',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }
        },
        grid: {
          borderColor: '#f3f3f3',
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19
          },
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        xaxis: {
          title: {
            text: 'Months'
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: -7
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories: finance.ar_yield && finance.ar_yield.map(x => x.month),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 5,
            // offsetY: 5
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            offsetX: -5,
            offsetY: 1
          },
          title: {
            text: 'Yield Percentage'
          },
          labels: {
            show: true,
            formatter: (value) => `${value} %`,
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },


        }
      }

    })
    setChartaging({
      series: finance.outstanding_aging && finance.outstanding_aging.map(x => x.val),
      options: {
        plotOptions: {
          pie: {
            donut: {

              labels: {
                show: true,
                name: {
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(
                      (a, b) => {
                        return a + b;
                      },
                      0
                    );
                  },
                },
              },
            },
          },
        },
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              customIcons: [{
                icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                index: 0,
                title: 'summary',
                class: 'custom-icon',
                click: function (chart, options, e) {
                  setIsTrendsView({ ...isTrendsView,title: "OutstandingAging",  isopen: true,isNew: true })
                  setScreenName("trenddetails")
                }
              }]
            },
            export: {
              csv: {
                filename: "OutstandingAging",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "OutstandingAging",
              },
              png: {
                filename: "OutstandingAging",
              }
            },
            autoSelected: 'zoom'
          },
        },
        title: {
          text: 'Outstanding Aging for Last 6 Months',
          align: 'center',
          style: {
            color: screen === "new" ? 'rgba(67, 147, 185, 1)' : "black"
          }

        },
        labels: finance.outstanding_aging && finance.outstanding_aging.map(x => x.label),
        colors: [
          "#F3B415",
          "#F27036",
          "#663F59",
          "#6A6E94",
          "#4E88B4",
          "#00A7C6",
          "#18D8D8",
          "#A9D794",
          "#46AF78",
          "#A93F55",
          "#8C5E58",
          "#2176FF",
          "#33A1FD",
          "#7A918D",
          "#BAFF29",
        ],
        dataLabels: {
          enabled: true,
        },
        legend: {

          show: true,
          offsetY: 50
        },

      },
    })
  }, [finance]);
  return (
    <>
      <div className="grid">
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {chartDS.options &&
              <ReactApexChart options={chartDS.options} series={chartDS.series} type="line" height={300} />}
          </Card>
        </div>
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {chartAR.options &&
              <ReactApexChart options={chartAR.options} series={chartAR.series} type="line" height={300} />}
          </Card>
        </div>
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {chartyield.options &&
              <ReactApexChart options={chartyield.options} series={chartyield.series} type="line" height={300} />}
          </Card>
        </div>
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
          {chartAE.options &&
              <ReactApexChart options={chartAE.options} series={chartAE.series} type="line" height={300} />}
       
          </Card>
        </div> 
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {chartaging1.options &&
              <ReactApexChart options={chartaging1.options} series={chartaging1.series} type="line" height={300} />}
          </Card>
        </div>
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
          {/* <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {chartaging.options && <>
              <ReactApexChart options={chartaging.options} series={chartaging.series} type="donut" height={270} />
              <div className="flex justify-content-center mr-8">
                <p style={{ fontWeight: 600 }}>Outstanding Aging Claim Details</p>
              </div>
            </>
            }
          </Card> */}
           <Card className="chartCard" style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2", height: "25rem", background: "#fff" }}>
            {embyMonth.options && <>
              <ReactApexChart options={embyMonth.options} series={embyMonth.series} type="line" height={300} />
              
            </>
            }
          </Card>
        </div> 
      </div>
    </>
  );
}
export default FinanceChat;