import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

const FormData = require("form-data");
function ApprovedOverhead() {
  const dt = useRef(null);
  const dtp = useRef(null);

  let [approvedoverhead, setApprovedoverhead] = useState([]);
  const [upload, setUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef();
  const fileRef = useRef(null);
  const [filters] = useState({
    project: { value: null, matchMode: FilterMatchMode.CONTAINS },
    provider_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    task: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    start_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    start_time: { value: null, matchMode: FilterMatchMode.CONTAINS },
    end_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    end_time: { value: null, matchMode: FilterMatchMode.CONTAINS },
    duration_decimal: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  useEffect(() => {
    getApprovedoverhead();
  }, []);
  const getApprovedoverhead = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}approvedoverhead`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setApprovedoverhead(response.data.data);
          setUpload(response.data.datahis);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const insertApprovedOverhead = (e) => {
    let data = new FormData();
    data.append("stream", e.files[0]);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}insertapprovedoverhead`, data)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getApprovedoverhead();

          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
    fileRef.current.clear();
  };
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Approved Overhead</span>
      <div style={{ display: "flex" }}>
        <Button
          label="Download CSV"
          className="csv m-1 mr-4"
          onClick={() => dt.current.exportCSV({ selectionOnly: false })}
          icon="pi pi-download"
        />
        <FileUpload
          ref={fileRef}
          mode="basic"
          customUpload
          auto={true}
          name="document"
          accept=".csv"
          maxFileSize={1000000}
          chooseLabel="Upload Approved Overhead CSV"
          className=" m-1 mr-4"
          uploadHandler={insertApprovedOverhead}
        />
        <Button
          className="m-1 mr-4"
          label="Sample File"
          icon="pi pi-download"
          type="button"
          onClick={async () => {
            const link = document.createElement("a");
            link.href = `${process.env.REACT_APP_API_KEY}tooltimedownload/clockify.csv`;
            document.body.appendChild(link);
            link.click();
            await sleep(200);
            link.parentNode.removeChild(link);
          }}
        ></Button>
      </div>
    </div>
  );
  const pheader = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Pillar Work</span>
      <div style={{ display: "flex" }}>
        <Button
          className="ml-3"
          label="Pillar Work CSV"
          icon="pi pi-download"
          type="button"
          onClick={async () => {
            dtp.current.exportCSV({ selectionOnly: false });
          }}
        ></Button>
      </div>
    </div>
  );
  const header1 = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Upload History</span>
    </div>
  );
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const deleteApprovedoverhead = (value) => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_KEY}deleteApprovedOverhead/${value}`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          getApprovedoverhead();
          toast?.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.data,
            life: 3000,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const confirm = (e) => {
    confirmDialog({
      message: `Do you want to delete ${e.file_name} Approved overhead file?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        deleteApprovedoverhead(e.created_date);
      },
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <DataTable
        loading={loading}
        filters={filters}
        filterDisplay="row"
        showGridlines
        stripedRows
        ref={dt}
        exportFilename={"Approved_Overhead_" + new Date().getTime()}
        value={approvedoverhead.filter((x) => x.project !== "Pillar Work")}
        resizableColumns={true}
        removableSort
        paginator
        rows={200}
        rowsPerPageOptions={[100, 200, 500, 1000]}
        header={header}
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "50rem" }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        className="financeDatatable"
      >
        <Column
          field="project"
          header="Project"
          filter
          filterPlaceholder="Search by Project"
          sortable
        ></Column>
        {/* <Column field="client" header="Client" filter filterPlaceholder="Search by Client" ></Column> */}
        <Column
          field="description"
          header="Description"
          bodyStyle={{ maxWidth: "300px" }}
          filter
          filterPlaceholder="Search by Description"
          sortable
        ></Column>
        <Column
          field="task"
          header="Task"
          filter
          filterPlaceholder="Search by Task"
          sortable
        ></Column>
        <Column
          field="provider_id"
          header="Provider ID"
          filter
          filterPlaceholder="Search by Provider ID"
          sortable
        ></Column>
        <Column
          field="user_"
          header="User(Provider)"
          filter
          filterPlaceholder="Search by User"
          sortable
        ></Column>
        {/* <Column field="group_" header="Group" filter filterPlaceholder="Search by Group" ></Column> */}
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by Email"
          sortable
        ></Column>
        {/* <Column field="tags" header="Tags" filter filterPlaceholder="Search by Tags" ></Column> */}
        <Column
          field="start_date"
          bodyStyle={{ minWidth: "200px" }}
          header="Start Date"
          filter
          filterPlaceholder="Search by Start Date"
          sortable
        ></Column>
        <Column
          field="start_time"
          header="Start Time"
          filter
          filterPlaceholder="Search by Start Time"
          sortable
        ></Column>
        <Column
          field="end_date"
          bodyStyle={{ minWidth: "200px" }}
          header="End Date"
          filter
          filterPlaceholder="Search by End Date"
          sortable
        ></Column>
        <Column
          field="end_time"
          header="End Time"
          filter
          filterPlaceholder="Search by End Time"
          sortable
        ></Column>
        <Column
          field="duration_decimal"
          header="Duration (Minutes)"
          filter
          filterPlaceholder="Search by Duration"
          body={(e) => {
            return e.duration_decimal && e.duration_decimal.toFixed(2);
          }}
          sortable
        ></Column>
        <Column
          field="created_date"
          header="Created Date"
          body={(e) => {
            return (
              e.created_date &&
              moment(new Date(e.created_date * 1).toGMTString()).format(
                "MM/DD/YYYY HH:mm:ss"
              )
            );
          }}
          sortable
        ></Column>
      </DataTable>
      <DataTable
        loading={loading}
        filters={filters}
        filterDisplay="row"
        showGridlines
        stripedRows
        ref={dtp}
        exportFilename={"Pillar_Work_" + new Date().getTime()}
        value={approvedoverhead.filter((x) => x.project === "Pillar Work")}
        resizableColumns={true}
        removableSort
        paginator
        rows={200}
        rowsPerPageOptions={[100, 200, 500, 1000]}
        header={pheader}
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "50rem" }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        className="financeDatatable"
      >
        <Column
          field="project"
          header="Project"
          filter
          filterPlaceholder="Search by Project"
          sortable
        ></Column>
        {/* <Column field="client" header="Client" filter filterPlaceholder="Search by Client" ></Column> */}
        <Column
          field="description"
          header="Description"
          bodyStyle={{ maxWidth: "300px" }}
          filter
          filterPlaceholder="Search by Description"
          sortable
        ></Column>
        <Column
          field="task"
          header="Task"
          filter
          filterPlaceholder="Search by Task"
          sortable
        ></Column>
        <Column
          field="provider_id"
          header="Provider ID"
          filter
          filterPlaceholder="Search by Provider ID"
          sortable
        ></Column>
        <Column
          field="user_"
          header="User(Provider)"
          filter
          filterPlaceholder="Search by User"
          sortable
        ></Column>
        {/* <Column field="group_" header="Group" filter filterPlaceholder="Search by Group" ></Column> */}
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by Email"
          sortable
        ></Column>
        {/* <Column field="tags" header="Tags" filter filterPlaceholder="Search by Tags" ></Column> */}
        <Column
          field="start_date"
          bodyStyle={{ minWidth: "200px" }}
          header="Start Date"
          filter
          filterPlaceholder="Search by Start Date"
          sortable
        ></Column>
        <Column
          field="start_time"
          header="Start Time"
          filter
          filterPlaceholder="Search by Start Time"
          sortable
        ></Column>
        <Column
          field="end_date"
          bodyStyle={{ minWidth: "200px" }}
          header="End Date"
          filter
          filterPlaceholder="Search by End Date"
          sortable
        ></Column>
        <Column
          field="end_time"
          header="End Time"
          filter
          filterPlaceholder="Search by End Time"
          sortable
        ></Column>
        <Column
          field="duration_decimal"
          header="Duration (Minutes)"
          filter
          filterPlaceholder="Search by Duration"
          body={(e) => {
            return e.duration_decimal && e.duration_decimal.toFixed(2);
          }}
          sortable
        ></Column>
        <Column
          field="created_date"
          header="Created Date"
          body={(e) => {
            return (
              e.created_date &&
              moment(new Date(e.created_date * 1).toGMTString()).format(
                "MM/DD/YYYY HH:mm:ss"
              )
            );
          }}
          sortable
        ></Column>
      </DataTable>
      <DataTable
        header={header1}
        loading={loading}
        value={upload}
        showGridlines
        stripedRows
        paginator
        rows={200}
        rowsPerPageOptions={[100, 200, 500, 1000]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "50rem" }}
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        className="financeDatatable mt-3"
      >
        {/* <Column field="date" header="Date" body={(e) => {
          return e.date && moment(e.date).format("MM/DD/YYYY")
        }} sortable></Column> */}
        <Column field="file_name" header="FileName" sortable></Column>
        <Column
          field="created_date"
          header="Created Date"
          body={(e) => {
            return (
              e.created_date &&
              moment(new Date(e.created_date * 1).toGMTString()).format(
                "MM/DD/YYYY HH:mm:ss"
              )
            );
          }}
          sortable
        ></Column>
        <Column
          field="action"
          header="Action"
          body={(e) => {
            return (
              <>
                <i
                  className="pi pi-download"
                  onClick={async () => {
                    const link = document.createElement("a");
                    link.href =
                      `${process.env.REACT_APP_API_KEY}pmtdownload/` + e.file;
                    document.body.appendChild(link);
                    link.click();
                    await sleep(200);
                    link.parentNode.removeChild(link);
                  }}
                  sortable
                ></i>
                <i
                  style={{ color: "red" }}
                  className="mt-1 ml-2 pi pi-trash"
                  onClick={() => {
                    confirm(e);
                  }}
                ></i>
              </>
            );
          }}
        ></Column>
      </DataTable>
    </>
  );
}
export default ApprovedOverhead;
