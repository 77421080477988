import axios from "axios";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { exportCSV } from "../Common/Common";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Chip } from "primereact/chip";
import img from "../assets/no.png";

function WaterfallReport({ filtedata, toast }) {
  const [totals, setTotals] = useState([]);
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const dt1 = useRef(null);
  const dtr = useRef(null);

  const getWaterfallReportData = (data) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}waterfall_report_data`, data)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setRates(response.data.data.rates);
          setTotals(response.data.data.totals);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const getWaterfallReportFile = (data) => {
    data = {
      rates: rates,
      totals: totals,
      fileName: "",
    };
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}waterfall_report_file`,

        data
      )
      .then(async function (response) {
        await sleep(3000);
        const link = document.createElement("a");
        link.href =
          `${process.env.REACT_APP_API_KEY}download/` +
          response.data.data.fileName;
        link.download = response.data.data.fileName;
        document.body.appendChild(link);
        link.click();
        await sleep(2000);
        document.body.removeChild(link);
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    getWaterfallReportData(filtedata);
  }, []);
  const headerTotals = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Dollar Value Totals</span>
      <div>
        <Tooltip target="button" position="bottom" />
        <Button
          size="small"
          type="button"
          icon="pi pi-download"
          className="csv m-1"
          label="Download"
          onClick={getWaterfallReportFile}
        />
        {/* <Button
          size="small"
          type="button"
          icon="pi pi-download"
          className="csv m-1"
          onClick={() => exportCSV(dt, false)}
          label="CSV"
        />
        <Button
          type="button"
          size="small"
          className="xls m-1"
          icon="pi pi-download"
          onClick={() => exportExcel("waterfall_report_totals", totals)}
          label="XLS"
        /> */}
      </div>
    </div>
  );
  const headerWater = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">WaterFall Report</span>
      <div>
        <Tooltip target="button" position="bottom" />
        <Button
          size="small"
          type="button"
          icon="pi pi-download"
          className="csv m-1"
          onClick={() => exportCSV(dtr, false)}
          label="CSV"
        />
        {/* <Button
          type="button"
          size="small"
          className="xls m-1"
          icon="pi pi-download"

          onClick={() => exportExcel("waterfall_report_totals",totals && totals.length > 0 &&rates && rates.length > 0 && totals.map((x,i)=>{
            const r=rates[i];
            return {...x,januaryr:r.january,februaryr:r.february,marchr:r.march,aprilr:r.april,
              mayr:r.may,juner:r.june,julyr:r.july,augustr:r.august,
              septemberr:r.september,octoberr:r.october,novemberr:r.november,decemberr:r.december,
              amount_collectedr:r.amount_collected,amount_outstandingr:r.amount_outstanding
            }
          }))}
          label="XLS"
        /> */}
      </div>
    </div>
  );
  const headerRates = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Collection Rates</span>
      <div>
        <Tooltip target="button" position="bottom" />
        {/* <Button
          size="small"
          type="button"
          icon="pi pi-download"
          className="csv m-1"
          onClick={() => exportCSV(dt1, false)}
          label="CSV"
        />
        <Button
          type="button"
          size="small"
          className="xls m-1"
          icon="pi pi-download"
          onClick={() => exportExcel("waterfall_report_rates", rates)}
          label="XLS"
        /> */}
      </div>
    </div>
  );
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals" style={{ textAlign: "right" }} />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_expected)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_expected)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.january)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].january)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.february)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].february)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.march)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].march)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.april)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].april)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.may)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].may)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.june)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].june)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.july)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].july)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.august)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].august)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.september)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].september)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.october)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].october)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.november)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].november)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.december)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].december)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_collected)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_collected)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_outstanding)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_outstanding)
          }
        />
        <Column header="Dollar Value" field="" />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.expected_collections)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].expected_collections)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.accrual_revenue)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].accrual_revenue)
          }
        />
      </Row>
    </ColumnGroup>
  );
  const footerGroupDv = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals" style={{ textAlign: "right" }} />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_expected)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_expected)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.january)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].january)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.february)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].february)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.march)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].march)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.april)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].april)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.may)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].may)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.june)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].june)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.july)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].july)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.august)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].august)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.september)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].september)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.october)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].october)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.november)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].november)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.december)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].december)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_collected)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_collected)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.amount_outstanding)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].amount_outstanding)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.expected_collections)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].expected_collections)
          }
        />
        <Column
          style={{ textAlign: "right" }}
          footer={
            totals &&
            totals.length > 0 &&
            "$" +
              (totals.length !== 1
                ? totals
                    .map((x) => x.accrual_revenue)
                    .reduce((a, c) => parseFloat(a) + parseFloat(c))
                    .toFixed(2)
                : totals[0].accrual_revenue)
          }
        />
      </Row>
    </ColumnGroup>
  );
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Month of Service" rowSpan={2} />
        <Column header="Amount Expected" rowSpan={2} />
        <Column header="January" colSpan={2} />
        <Column header="February" colSpan={2} />
        <Column header="March" colSpan={2} />
        <Column header="April" colSpan={2} />
        <Column header="May" colSpan={2} />
        <Column header="June" colSpan={2} />
        <Column header="July" colSpan={2} />
        <Column header="August" colSpan={2} />
        <Column header="September" colSpan={2} />
        <Column header="October" colSpan={2} />
        <Column header="November" colSpan={2} />
        <Column header="December" colSpan={2} />
        <Column header="Amount Collected" rowSpan={2} />
        <Column header="% Collected" rowSpan={2} />
        <Column header="Amount Outstanding" rowSpan={2} />
        <Column header="% Not Collected" rowSpan={2} />
        <Column header="Expected Collections" rowSpan={2} />
        <Column header="Accrual Revenue" rowSpan={2} />
      </Row>
      <Row>
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
        <Column header="Dollar Value" field="" />
        <Column header="Rates" field="" />
      </Row>
    </ColumnGroup>
  );
  return totals && totals.length > 0 && rates && rates.length > 0 ? (
    <div className="grid">
      {/* <div className="col-12">
        <Card
          loading={loading}
          style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2" }}
        >
          {totals && totals.length > 0 && rates && rates.length > 0 && (
            <DataTable
              headerColumnGroup={headerGroup}
              footerColumnGroup={footerGroup}
              showGridlines
              stripedRows
              resizableColumns={true}
              removableSort
              scrollable
              scrollHeight="400px"
              tableStyle={{ minWidth: "50rem" }}
              header={headerWater}
              loading={loading}
              ref={dtr}
              value={totals.map((x, i) => {
                const r = rates[i];
                return {
                  ...x,
                  januaryr: r.january,
                  februaryr: r.february,
                  marchr: r.march,
                  aprilr: r.april,
                  mayr: r.may,
                  juner: r.june,
                  julyr: r.july,
                  augustr: r.august,
                  septemberr: r.september,
                  octoberr: r.october,
                  novemberr: r.november,
                  decemberr: r.december,
                  amount_collectedr: r.amount_collected,
                  amount_outstandingr: r.amount_outstanding,
                };
              })}
              className="financeDatatable"
            >
              <Column
                field="month_name"
                header="Month of Service"
                key="month_name"
              />
              <Column
                field="amount_expected"
                header="Amount Expected"
                key="amount_expected"
                align="right"
                body={(e) => "$" + e.amount_expected}
              />
              <Column
                field="january"
                header="January"
                key="january"
                align="right"
                body={(e) => "$" + e.january}
              />
              <Column
                field="januaryr"
                header="January"
                key="januaryr"
                align="right"
                body={(e) => e.januaryr + "%"}
              />
              <Column
                field="february"
                header="February"
                key="february"
                align="right"
                body={(e) => "$" + e.february}
              />
              <Column
                field="februaryr"
                header="February"
                key="februaryr"
                align="right"
                body={(e) => e.februaryr + "%"}
              />
              <Column
                field="march"
                header="March"
                key="march"
                align="right"
                body={(e) => "$" + e.march}
              />
              <Column
                field="marchr"
                header="March"
                key="marchr"
                align="right"
                body={(e) => e.marchr + "%"}
              />
              <Column
                field="april"
                header="April"
                key="april"
                align="right"
                body={(e) => "$" + e.april}
              />
              <Column
                field="aprilr"
                header="April"
                key="aprilr"
                align="right"
                body={(e) => e.aprilr + "%"}
              />
              <Column
                field="may"
                header="May"
                key="may"
                align="right"
                body={(e) => "$" + e.may}
              />
              <Column
                field="mayr"
                header="May"
                key="mayr"
                align="right"
                body={(e) => e.mayr + "%"}
              />
              <Column
                field="june"
                header="June"
                key="june"
                align="right"
                body={(e) => "$" + e.june}
              />
              <Column
                field="juner"
                header="June"
                key="juner"
                align="right"
                body={(e) => e.juner + "%"}
              />
              <Column
                field="july"
                header="July"
                key="july"
                align="right"
                body={(e) => "$" + e.july}
              />
              <Column
                field="julyr"
                header="July"
                key="julyr"
                align="right"
                body={(e) => e.julyr + "%"}
              />
              <Column
                field="august"
                header="August"
                key="august"
                align="right"
                body={(e) => "$" + e.august}
              />
              <Column
                field="augustr"
                header="August"
                key="augustr"
                align="right"
                body={(e) => e.augustr + "%"}
              />
              <Column
                field="september"
                header="September"
                key="september"
                align="right"
                body={(e) => "$" + e.september}
              />
              <Column
                field="septemberr"
                header="September"
                key="septemberr"
                align="right"
                body={(e) => e.septemberr + "%"}
              />
              <Column
                field="october"
                header="October"
                key="october"
                align="right"
                body={(e) => "$" + e.october}
              />
              <Column
                field="octoberr"
                header="October"
                key="octoberr"
                align="right"
                body={(e) => e.octoberr + "%"}
              />
              <Column
                field="november"
                header="November"
                key="november"
                align="right"
                body={(e) => "$" + e.november}
              />
              <Column
                field="novemberr"
                header="November"
                key="novemberr"
                align="right"
                body={(e) => e.novemberr + "%"}
              />
              <Column
                field="december"
                header="December"
                key="december"
                align="right"
                body={(e) => "$" + e.december}
              />
              <Column
                field="decemberr"
                header="December"
                key="decemberr"
                align="right"
                body={(e) => e.decemberr + "%"}
              />
              <Column
                field="amount_collected"
                header="Amount Collected"
                key="amount_collected"
                align="right"
                body={(e) => "$" + e.amount_collected}
              />
              <Column
                field="amount_collectedr"
                header="Amount Collected"
                key="amount_collected"
                align="right"
                body={(e) => e.amount_collectedr + "%"}
              />
              <Column
                field="amount_outstanding"
                header="Amount Outstanding"
                key="amount_outstanding"
                align="right"
                body={(e) => "$" + e.amount_outstanding}
              />
              <Column
                field="amount_outstandingr"
                header="Amount Outstanding"
                key="amount_outstanding"
                align="right"
                body={(e) => e.amount_outstandingr + "%"}
              />
              <Column
                field="expected_collections"
                header="Expected Collections"
                key="expected_collections"
                align="right"
                body={(e) => "$" + e.expected_collections}
              />
              <Column
                field="accrual_revenue"
                header="Accrual Revenue"
                key="accrual_revenue"
                align="right"
                body={(e) => "$" + e.accrual_revenue}
              />
            </DataTable>
          )}
        </Card>
      </div> */}
      <div className="col-12">
        <Card
          loading={loading}
          style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2" }}
        >
          {totals && totals.length > 0 && (
            <DataTable
              footerColumnGroup={footerGroupDv}
              showGridlines
              stripedRows
              resizableColumns={true}
              removableSort
              scrollable
              scrollHeight="400px"
              tableStyle={{ minWidth: "50rem" }}
              header={headerTotals}
              loading={loading}
              ref={dt}
              value={totals}
              className="financeDatatable"
            >
              <Column
                field="month_name"
                header="Month of Service"
                key="month_name"
              />
              <Column
                field="amount_expected"
                header="Amount Expected"
                key="amount_expected"
                align="right"
                body={(e) => "$" + e.amount_expected}
              />
              <Column
                field="january"
                header="January"
                key="january"
                align="right"
                body={(e) => "$" + e.january}
              />
              <Column
                field="february"
                header="February"
                key="february"
                align="right"
                body={(e) => "$" + e.february}
              />
              <Column
                field="march"
                header="March"
                key="march"
                align="right"
                body={(e) => "$" + e.march}
              />
              <Column
                field="april"
                header="April"
                key="april"
                align="right"
                body={(e) => "$" + e.april}
              />
              <Column
                field="may"
                header="May"
                key="may"
                align="right"
                body={(e) => "$" + e.may}
              />
              <Column
                field="june"
                header="June"
                key="june"
                align="right"
                body={(e) => "$" + e.june}
              />
              <Column
                field="july"
                header="July"
                key="july"
                align="right"
                body={(e) => "$" + e.july}
              />
              <Column
                field="august"
                header="August"
                key="august"
                align="right"
                body={(e) => "$" + e.august}
              />
              <Column
                field="september"
                header="September"
                key="september"
                align="right"
                body={(e) => "$" + e.september}
              />
              <Column
                field="october"
                header="October"
                key="october"
                align="right"
                body={(e) => "$" + e.october}
              />
              <Column
                field="november"
                header="November"
                key="november"
                align="right"
                body={(e) => "$" + e.november}
              />
              <Column
                field="december"
                header="December"
                key="december"
                align="right"
                body={(e) => "$" + e.december}
              />
              <Column
                field="amount_collected"
                header="Amount Collected"
                key="amount_collected"
                align="right"
                body={(e) => "$" + e.amount_collected}
              />
              <Column
                field="amount_outstanding"
                header="Amount Outstanding"
                key="amount_outstanding"
                align="right"
                body={(e) => "$" + e.amount_outstanding}
              />
              <Column
                field="expected_collections"
                header="Expected Collections"
                key="expected_collections"
                align="right"
                body={(e) => "$" + e.expected_collections}
              />
              <Column
                field="accrual_revenue"
                header="Accrual Revenue"
                key="accrual_revenue"
                align="right"
                body={(e) => "$" + e.accrual_revenue}
              />
            </DataTable>
          )}
        </Card>
      </div>
      <div className="col-12">
        <Card
          loading={loading}
          style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2" }}
        >
          {rates && rates.length > 0 && (
            <DataTable
              showGridlines
              stripedRows
              resizableColumns={true}
              removableSort
              scrollable
              scrollHeight="400px"
              tableStyle={{ minWidth: "50rem" }}
              header={headerRates}
              title="Collection Rates"
              loading={loading}
              ref={dt1}
              value={rates}
              className="financeDatatable"
            >
              <Column
                field="month_name"
                header="Month of Service"
                key="month_name"
              />
              <Column
                field="january"
                header="January"
                key="january"
                align="right"
                body={(e) => e.january + "%"}
              />
              <Column
                field="february"
                header="February"
                key="february"
                align="right"
                body={(e) => e.february + "%"}
              />
              <Column
                field="march"
                header="March"
                key="march"
                align="right"
                body={(e) => e.march + "%"}
              />
              <Column
                field="april"
                header="April"
                key="april"
                align="right"
                body={(e) => e.april + "%"}
              />
              <Column
                field="may"
                header="May"
                key="may"
                align="right"
                body={(e) => e.may + "%"}
              />
              <Column
                field="june"
                header="June"
                key="june"
                align="right"
                body={(e) => e.june + "%"}
              />
              <Column
                field="july"
                header="July"
                key="july"
                align="right"
                body={(e) => e.july + "%"}
              />
              <Column
                field="august"
                header="August"
                key="august"
                align="right"
                body={(e) => e.august + "%"}
              />
              <Column
                field="september"
                header="September"
                key="september"
                align="right"
                body={(e) => e.september + "%"}
              />
              <Column
                field="october"
                header="October"
                key="october"
                align="right"
                body={(e) => e.october + "%"}
              />
              <Column
                field="november"
                header="November"
                key="november"
                align="right"
                body={(e) => e.november + "%"}
              />
              <Column
                field="december"
                header="December"
                key="december"
                align="right"
                body={(e) => e.december + "%"}
              />
              <Column
                field="amount_collected"
                header="% Collected"
                key="amount_collected"
                align="right"
                body={(e) => e.amount_collected + "%"}
              />
              <Column
                field="amount_outstanding"
                header="% Not Collected"
                key="amount_outstanding"
                align="right"
                body={(e) => e.amount_outstanding + "%"}
              />
            </DataTable>
          )}
        </Card>
      </div>
    </div>
  ) : (
    <div className="grid">
      <div className="col-12">
        <div className="card flex justify-content-center">
          <Chip image={img} label="No Data"></Chip>
        </div>
      </div>
    </div>
  );
}
export default WaterfallReport;
