import React, { useEffect, useState, useRef } from "react";
import { Tooltip } from "primereact/tooltip";
import { Panel } from "primereact/panel";
function EmployeeCard({loading,cards}) {
  return (
    <>

      <div className="m-0 col-12">
        <div className="grid gap" >
          <Tooltip position='bottom' target=".amount_expected" />

          <Panel style={{ cursor: 'pointer', textAlign: "center", width: "13rem", }}
            header={<></>}
            className="col align-items-center amount_expected panelCardHeader">
            <div className="text-xl text-400  cardText">Employment Hours</div>
            <span className="text-xl text-400   text-primary cardNumber">{(cards[0]?.value/60).toFixed(2)}</span>
          </Panel>

          <Tooltip position='bottom' target=".amount_allowed" />

          <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }}
            header={<></>}
            className="col align-items-center justify-content-center amount_allowed newamount_allowed panelCardHeader">
            <div className="text-xl text-400  cardText" >Approved Overhead (Hours)</div>
            <span className="text-xl text-400   text-primary cardNumber AACardNumber  text-yellow-500">{(cards[1]?.value/60).toFixed(2)}</span>
          </Panel>

          <Tooltip position='bottom' target=".amount_denied" />

          <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }} header={<></>}
            className="col align-items-center justify-content-center amount_denied newamount_denied panelCardHeader">
            <div className="text-xl text-400  cardText" >Interaction Time (Hours)</div>
            <span className="text-xl text-400   text-primary cardNumber ADCardNumber  text-red-500">{(cards[2]?.value/60).toFixed(2)}</span>
          </Panel>

          <Tooltip position='bottom' target=".amount_paid" />

          <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }} header={<></>}
            className="col align-items-center justify-content-center amount_paid newamount_paid panelCardHeader">
            <div className="text-xl text-400  cardText" >Other Tool Time (Hours)</div>
            <span className="text-xl text-400   text-primary cardNumber APCardNumber  text-green-500">{(cards[3]?.value/60).toFixed(2)}</span>
          </Panel>

        </div>
      </div>
    </>
  )
}
export default EmployeeCard