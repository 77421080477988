import React, { useState } from "react";  
import Employment from "./Employment";
import TimeTool from "./TimeTool";
import ApprovedOverhead from "./ApprovedOverhead";
import { TabView, TabPanel } from "primereact/tabview";
import Dashboard from "./Dashboard";
function Pmt() {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className=" grid flex gap-3 py-4 px-2">
            <div className=" col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh", padding: '0px' }}>
                <div className="card" style={{ padding: "10px" }}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Dashboard">  
                        <Dashboard/>                          
                        </TabPanel>
                        <TabPanel header="Tool Time">
                            <TimeTool/>
                        </TabPanel>
                        <TabPanel header="Approved Overhead">
                            <ApprovedOverhead/>
                        </TabPanel>
                        <TabPanel header="Employment"  >
                         <Employment/>
                     </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default Pmt;