import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment, { min } from "moment";
import { FilterMatchMode } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { exportCSV, exportExcel } from "../Common/Common";
import { Dialog } from "primereact/dialog";

function EmployeeChart({ filtedata, loading, nloading, dashboard, notes }) {
  const dt = useRef(null);
  const dt1 = useRef(null);
  const dt2 = useRef(null);

  const [filters] = useState({
    provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
    known_as: { value: null, matchMode: FilterMatchMode.CONTAINS },
    patient: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reason: { value: null, matchMode: FilterMatchMode.CONTAINS },
    context: { value: null, matchMode: FilterMatchMode.CONTAINS },
    contents: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [lable, setLable] = useState(
    filtedata &&
      (filtedata.rtype === "M"
        ? "Month"
        : filtedata.rtype === "W"
        ? "Week"
        : "Day")
  );
  const [pvisible, setPVisible] = useState(false);
  const [brvisible, setBRVisible] = useState(false);
  const [irvisible, setBIVisible] = useState(false);
  const [wrvisible, setWRVisible] = useState(false);

  const [per, setPer] = useState({ options: null, series: [] });
  const [pi, setPI] = useState({ options: null, series: [] });
  const [ptd, setPTD] = useState({ options: null, series: [] });

  const [wp, setWP] = useState({ options: null, series: [] });
  const [wbr, setWBR] = useState({ options: null, series: [] });
  const [wir, setWiR] = useState({ options: null, series: [] });
  const [wr, setWR] = useState({ options: null, series: [] });
  const [timeComparison, setTimeComparison] = useState({
    options: null,
    series: [],
  });

  const [time_vs_pannelsize, setTime_vs_Pannelsize] = useState({
    options: null,
    series: [],
  });
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  useEffect(() => {
    setLable(
      filtedata &&
        (filtedata.rtype === "M"
          ? "Month"
          : filtedata.rtype === "W"
          ? "Week"
          : "Day")
    );
  }, [filtedata]);
  useEffect(() => {
    const colors = [];
    for (let i = 0; i < dashboard.time_comparison.length + 50; i++) {
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      colors.push(randomColor);
    }
    let dates = [];
    var ComparisonData = dashboard.time_comparison.map((x) => {
      return {
        name: x.label,
        type: "line",
        data:
          x.val &&
          x.val.map((a) => {
            dates.push(a._date);
            return a.value;
          }),
      };
    });
    dates = dates.filter(onlyUnique);
    if (colors.length > 0 && dates.length > 0) {
      setTimeComparison({
        series: ComparisonData,
        options: {
          chart: {
            height: 350,
            stacked: false,
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
              tools: {
                download: true,
              },
            },
          },
          colors: colors,
          dataLabels: {
            enabled: false,

            offsetY: -7,
            offsetX: 6,
            style: {
              colors: ["#000"],
              fontWeight: "bold",
              fontSize: "12px",
            },
            background: {
              enabled: false,
            },
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          title: {
            text: "Direct Time Comparison",
            align: "center",
            style: {
              color: "rgba(67, 147, 185, 1)",
            },
          },
          grid: {
            borderColor: "#f3f3f3",
            padding: {
              top: 0,
              right: 40,
              bottom: 0,
              left: 19,
            },
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          markers: {
            size: 5,
            hover: {
              size: 7,
              sizeOffset: 3,
            },
          },
          xaxis: {
            title: {
              text: lable,
            },
            axisBorder: {
              show: true,
              color: "#78909C",
              offsetX: -12,
            },
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#78909C",
            },
            labels: {
              show: true,
              style: {
                colors: [],
                fontWeight: 600,
              },
            },
            categories: dates,
          },
          yaxis: {
            show: true,
            forceNiceScale: true,
            axisBorder: {
              show: true,
              color: "#78909C",
              offsetX: 5,
            },
            min: 0,
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#78909C",
              offsetX: -5,
              offsetY: 1,
            },
            title: {
              text: "Direct Time",
            },
            labels: {
              show: true,
              formatter: (value) => value.toFixed(2),
              style: {
                colors: ["#4d4d4d"],
                fontWeight: 600,
              },
            },
          },
        },
      });
    }

    setTime_vs_Pannelsize({
      series: [
        {
          name: "Time",
          type: "column",
          data:
            dashboard.time_size &&
            dashboard.time_size.map((x) => {
              return x.total_time;
            }),
        },
        {
          name: "Panel Size",
          type: "line",
          data:
            dashboard.time_size &&
            dashboard.time_size.map((x) => {
              return x.pannel_size;
            }),
        },
      ],
      options: {
        chart: {
          height: 350,
          stacked: true,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        colors: ["#126BBD", "#419464"],
        dataLabels: {
          enabled: false,

          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        title: {
          text: "Total Time vs Panel Size",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: lable,
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.time_size &&
            dashboard.time_size.map((x) => {
              return x.date;
            }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Total Time and Panel Size",
          },
          labels: {
            show: true,
            formatter: (value) => value,
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
    setPer({
      series: [
        {
          name: "Employment",
          type: "line",
          data:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart.map((x) => {
              return (x.eh / 60).toFixed(2);
            }),
        },
        {
          name: "Approved Overhead",
          type: "column",
          data:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart.map((x) => {
              return (x.ao / 60).toFixed(2);
            }),
        },
        {
          name: "Interaction Time",
          type: "column",
          data:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart.map((x) => {
              return (x.it / 60).toFixed(2);
            }),
        },
        {
          name: "Other Tool Time",
          type: "column",
          data:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart.map((x) => {
              return (x.ot / 60).toFixed(2);
            }),
        },
      ],
      options: {
        chart: {
          height: 350,
          stacked: true,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        colors: ["#126BBD", "#E89C43", "#F96363", "#419464"],
        dataLabels: {
          enabled: false,

          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        title: {
          text: "Performance",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: lable,
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart.map((x) => {
              return x.date;
            }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Time",
          },
          labels: {
            show: true,
            formatter: (value) => value,
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
    setPI({
      series: [
        {
          name: "Interaction Count",
          data:
            dashboard.patient_interactions &&
            dashboard.patient_interactions.map((x) => x.count_patient),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        colors: ["#326fd1"],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {},
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        title: {
          text: "Patient Interactions",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: "Minutes",
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.patient_interactions &&
            dashboard.patient_interactions.map((x) => x.time_interval),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Interaction Count",
          },
          labels: {
            show: true,
            formatter: (value) => value,
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
    setPTD({
      series: [
        {
          name: "Patient Count",
          data:
            dashboard.patient_time_distribution_chart &&
            dashboard.patient_time_distribution_chart.map((x) => x.val),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '<i class="pi pi-file-export" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                  index: 0,
                  title: "CSV",
                  class: "custom-icon",
                  click: function (chart, options, e) {
                    exportCSV(dt1, false);
                  },
                },
              ],
              download: true,
            },
          },
        },
        colors: ["#326fd1"],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {},
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        title: {
          text: "Patient Time Distribution",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: "Minutes",
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.patient_time_distribution_chart &&
            dashboard.patient_time_distribution_chart.map((x) => x.label),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Patient Count",
          },
          labels: {
            show: true,
            formatter: (value) => value,
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
    
   setWP({
  series: [
    {
      name: "Productivity",
      data:
        dashboard.weekly_productivity &&
        dashboard.weekly_productivity.map((x) => x.productivity),
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px ;font-size:16px">',
              index: 0,
              title: "Formula",
              class: "custom-icon",
              click: function (chart, options, e) {
                setPVisible(true);
              },
            },
            {
              icon: '<i class="pi pi-file-export" width="40" style="padding:4px 15px;font-size:16px">',
              index: 0,
              title: "CSV",
              class: "custom-icon",
              click: function (chart, options, e) {
                exportCSV(dt2, false);
              },
            },
          ],
        },
      },
    },
    colors: ["#4c0a8a"],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {},
      offsetY: -7,
      offsetX: 6,
      style: {
        colors: ["#000"],
        fontWeight: "bold",
        fontSize: "12px",
      },
      background: {
        enabled: false,
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },

    annotations: {
      yaxis: [
        {
          y: 100,
          borderColor: "#808080",
          label: {
            borderColor: "#808080",
            style: {
              color: "#fff",
              background: "#808080",
            },
            text: "Target (100%)",
          },
        },
        {
          y:
          dashboard.weekly_productivity &&
          dashboard.weekly_productivity.length > 0
            ? dashboard.weekly_productivity
                .map((x) => x.productivity)
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              dashboard.weekly_productivity.length
            : 0,
            // dashboard.weekly_productivity &&
            // dashboard.weekly_productivity
            //   .map((x) => x.productivity)
            //   .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
            //   dashboard.weekly_productivity.map((x) => x.productivity).length,
          borderColor: "green",
          label: {
            borderColor: "green",
            style: {
              color: "#fff",
              background: "green",
            },
            text:
              "Average (" +
              
              (dashboard.weekly_productivity &&
              dashboard.weekly_productivity.length > 0
                ? (
                    dashboard.weekly_productivity
                      .map((x) => x.productivity)
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                    dashboard.weekly_productivity.map((x) => x.productivity)
                      .length
                  ).toFixed(2)
                : 0) +
              "%)",
          },
        },
        {
          y: 0,
          y2: 100,
          borderColor: "#000",
          fillColor:  dashboard.weekly_productivity && dashboard.weekly_productivity.length > 0 ? "#FEB019" : "#FFFFFF",
          opacity: 0.2,
          label: {
            display: "none",
          },
        },
        {
          y: 100,
          y2: 200,
          borderColor: "#000",
          fillColor: "green",
          opacity: 0.2,
          label: {
            display: "none",
          },
        },
      ],
    },
    title: {
      text: "Productivity",
      align: "center",
      style: {
        color: "rgba(67, 147, 185, 1)",
      },
    },
    grid: {
      borderColor: "#f3f3f3",
      padding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 19,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
        sizeOffset: 3,
      },
    },
    xaxis: {
      title: {
        text: lable,
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: -12,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
      },
      labels: {
        show: true,
        style: {
          colors: [],
          fontWeight: 600,
        },
      },
      categories:
        dashboard.weekly_productivity &&
        dashboard.weekly_productivity.map((x) => x.week),
    },
    yaxis: {
      show: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 5,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        offsetX: -5,
        offsetY: 1,
      },
      title: {
        text: "Productivity Rate",
      },
      labels: {
        show: true,
        formatter: (value) => value.toFixed(0) + "%",
        style: {
          colors: ["#4d4d4d"],
          fontWeight: 600,
        },
      },
      // min: 0,
      min: dashboard.weekly_productivity && dashboard.weekly_productivity.length > 0
      ? undefined // Let the chart auto-calculate the min value when there is data
      : 0,
      max: dashboard.weekly_productivity && dashboard.weekly_productivity.length > 0
      ? Math.max(100, Math.max(...dashboard.weekly_productivity.map((x) => x.productivity))) // Adjust max value based on data
      : 5,
      // max: Math.max(100, Math.max(...(dashboard.weekly_productivity ? dashboard.weekly_productivity.map((x) => x.productivity) : [100]))), // Ensures Y-axis includes 100
      tickAmount: 6,

    },
  },
});


    setWBR({
      series: [
        {
          name: "Direct Rate",
          data:
            dashboard.weekly_billingrate &&
            dashboard.weekly_billingrate.map((x) => x.billing_rate),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px  ;font-size:16px">',
                  index: 0,
                  title: "Formula",
                  class: "custom-icon",
                  click: function (chart, options, e) {
                    setBRVisible(true);
                  },
                },
                {
                  icon: '<i class="pi pi-file-export" width="40" style="padding:4px 15px  ;font-size:16px ">',
                  index: 0,
                  title: "CSV",
                  class: "custom-icon",
                  click: function (chart, options, e) {
                    exportCSV(dt2, false);
                  },
                },
              ],
              download: true,
            },
          },
        },
        colors: ["#4c0a8a"],

        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {},
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },

        annotations: {
          yaxis: [
            {
              y: 80,
              borderColor: "#808080",
              label: {
                borderColor: "#808080",
                style: {
                  color: "#fff",
                  background: "#808080",
                },
                text: "Target (80%)",
              },
            },
            {
              y:
              dashboard.weekly_billingrate &&
          dashboard.weekly_billingrate.length > 0
            ? dashboard.weekly_billingrate
                .map((x) => x.billing_rate)
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              dashboard.weekly_billingrate.length
            : 0,
                // dashboard.weekly_billingrate &&
                // dashboard.weekly_billingrate
                //   .map((x) => x.billing_rate)
                //   .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                //   dashboard.weekly_billingrate
                //     .map((x) => x.billing_rate)
                //     .map((x) => x.productivity).length,
              borderColor: "green",
              strokeDashArray: 1,
              label: {
                borderColor: "green",
                style: {
                  color: "#fff",
                  background: "green",
                },
                text:
                  "Average (" +
                  (dashboard.weekly_billingrate &&
                  dashboard.weekly_billingrate.length > 0
                    ? (
                        dashboard.weekly_billingrate
                          .map((x) => x.billing_rate)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                        dashboard.weekly_billingrate
                          .map((x) => x.billing_rate)
                          .map((x) => x.productivity).length
                      ).toFixed(2)
                    : 0) +
                  "%)",
              },
            },
            {
              y: 0,
              y2: 80,
              borderColor: "#000",
              fillColor: dashboard.weekly_billingrate && dashboard.weekly_billingrate.length > 0 ?"#FEB019" : "#FFFFFF",
              opacity: 0.2,
              label: {
                display: "none",
              },
            },
            {
              y: 80,
              y2: 200,
              borderColor: "#000",
              fillColor: "green",
              opacity: 0.2,
              label: {
                display: "none",
              },
            },
          ],
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        title: {
          text: "Direct Rate",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        xaxis: {
          title: {
            text: lable,
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.weekly_billingrate &&
            dashboard.weekly_billingrate.map((x) => x.week_number),
        },
        yaxis: {
          show: true,
          
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Direct Rate",
          },
          labels: {
            show: true,
            formatter: (value) => value.toFixed(0) + "%",
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
          // min: 0,
      min: dashboard.weekly_billingrate && dashboard.weekly_billingrate.length > 0
      ? undefined // Let the chart auto-calculate the min value when there is data
      : 0,
      max: dashboard.weekly_billingrate && dashboard.weekly_billingrate.length > 0
      ? Math.max(100, Math.max(...dashboard.weekly_billingrate.map((x) => x.billing_rate))) // Adjust max value based on data
      : 5,
      tickAmount: 6,
          // max: Math.max(100, Math.max(...(dashboard.weekly_billingrate ? dashboard.weekly_billingrate.map((x) => x.billing_rate) : [100]))), // Ensures Y-axis includes 100
          // tickAmount: 6,
        },
      },
    });
    setWiR({
      series: [
        {
          name: "Indirect Rate",
          data:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart
              .filter((x) => parseFloat(x.eh) > 0)
              .map((x) => {
                return (
                  (parseFloat(x.ao) * 100) /
                  (parseFloat(x.eh) === 0 ? 1 : parseFloat(x.eh))
                ).toFixed(2);
              }),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                  index: 0,
                  title: "Formula",
                  class: "custom-icon",
                  click: function (chart, options, e) {
                    setBIVisible(true);
                  },
                },
              ],
              download: true,
            },
          },
        },
        colors: ["#edc641"],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {},
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        title: {
          text: "Indirect Rate",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: lable,
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },
          categories:
            dashboard.clustered_column_chart &&
            dashboard.clustered_column_chart
              .filter((x) => parseFloat(x.eh) > 0)
              .map((x) => {
                return x.date;
              }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Indirect Rate",
          },
          labels: {
            show: true,
            formatter: (value) => value.toFixed(0) + "%",
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
    setWR({
      series: [
        {
          name: "Work Rate",
          data:
            dashboard.work_rate_data &&
            dashboard.work_rate_data
              .filter((x) => parseFloat(x.work_rate) > 0)
              .map((x) => {
                return parseFloat(x.work_rate).toFixed(2);
              }),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '<i class="pi pi-exclamation-circle" width="40" style="padding:4px 10px 0px ;font-size:16px">',
                  index: 0,
                  title: "Formula",
                  class: "custom-icon",
                  click: function (chart, options, e) {
                    setWRVisible(true);
                  },
                },
              ],
              download: true,
            },
          },
        },
        colors: ["#edc641"],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {},
          offsetY: -7,
          offsetX: 6,
          style: {
            colors: ["#000"],
            fontWeight: "bold",
            fontSize: "12px",
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        annotations: {
          yaxis: [
           
            {
              y:
                dashboard.work_rate_data &&
                dashboard.work_rate_data
                  .map((x) => x.work_rate)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                  dashboard.work_rate_data
                    .map((x) => x.work_rate)
                    .map((x) => x.productivity).length,
              borderColor: "green",
              strokeDashArray: 1,
              label: {
                borderColor: "green",
                style: {
                  color: "#fff",
                  background: "green",
                },
                text:
                  "Average (" +
                  (dashboard.work_rate_data &&
                  dashboard.work_rate_data.length > 0
                    ? (
                        dashboard.work_rate_data
                          .map((x) => x.work_rate)
                          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                        dashboard.work_rate_data
                          .map((x) => x.work_rate)
                          .map((x) => x.productivity).length
                      ).toFixed(2)
                    : 0) +
                  "%)",
              },
            },
           
          ],
        },
        title: {
          text: "Work Rate",
          align: "center",
          style: {
            color: "rgba(67, 147, 185, 1)",
          },
        },
        grid: {
          borderColor: "#f3f3f3",
          padding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 19,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
            sizeOffset: 3,
          },
        },
        xaxis: {
          title: {
            text: lable,
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -12,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
          },
          labels: {
            show: true,
            style: {
              colors: [],
              fontWeight: 600,
            },
          },

          categories:
            dashboard.work_rate_data &&
            dashboard.work_rate_data
              .filter((x) => parseFloat(x.work_rate) > 0)
              .map((x) => {
                return x.date;
              }),
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 5,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            offsetX: -5,
            offsetY: 1,
          },
          title: {
            text: "Work Rate",
          },
          labels: {
            show: true,
            formatter: (value) => value.toFixed(0) + "%",
            style: {
              colors: ["#4d4d4d"],
              fontWeight: 600,
            },
          },
        },
      },
    });
  }, [dashboard]);

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Notes</span>
      <div>
        <Tooltip target="button" position="bottom" />
        {dashboard.notes && dashboard.notes.length > 0 && (
          <>
            <Button
              size="small"
              type="button"
              icon="pi pi-download"
              className="csv m-1"
              onClick={() => exportCSV(dt, false)}
              label="CSV"
            />
            <Button
              type="button"
              size="small"
              className="xls m-1"
              icon="pi pi-download"
              onClick={() => exportExcel("notes", dashboard.notes)}
              label="XLS"
            />
          </>
        )}
      </div>
    </div>
  );
  const bodyTemplate = (data, col) => {
    return data[col] ? data[col] : "";
  };

  return (
    <div className="grid " style={{ margin: "2px" }}>
      <Dialog
        header="Indirect Rate"
        visible={irvisible}
        style={{ width: "500px" }}
        onHide={() => setBIVisible(false)}
      >
        <div className="align-items-center justify-content-center">
          <div className="grid">
            <div className="col-3">
              <p className="mt-5 pt-2 flex justify-content-center flex-wrap">
                Indirect Rate =
              </p>
            </div>
            <div className="col-9">
              <p className="flex justify-content-center flex-wrap">
                <span style={{ color: "#E89C43", fontWeight: "bold" }}>
                  Approved Overhead
                </span>
              </p>
              <p
                className="flex justify-content-center flex-wrap pt-3 border-top-1"
                style={{ color: "#126BBD", fontWeight: "bold" }}
              >
                Employment Hours
              </p>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Direct Rate"
        visible={brvisible}
        style={{ width: "500px" }}
        onHide={() => setBRVisible(false)}
      >
        <div className="align-items-center justify-content-center">
          <div className="grid">
            <div className="col-3">
              <p className="mt-5 pt-2 flex justify-content-center flex-wrap">
                Direct Rate =
              </p>
            </div>
            <div className="col-9">
              <p className="flex justify-content-center flex-wrap">
                <span style={{ color: "#F96363", fontWeight: "bold" }}>
                  Interaction Time
                </span>
                &nbsp;&nbsp; +&nbsp;&nbsp;{" "}
                <span style={{ color: "#419464", fontWeight: "bold" }}>
                  Other Tool Time
                </span>
              </p>
              <p
                className="flex justify-content-center flex-wrap pt-3 border-top-1"
                style={{ color: "#126BBD", fontWeight: "bold" }}
              >
                Employment Hours
              </p>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Productivity"
        visible={pvisible}
        style={{ width: "600px" }}
        onHide={() => setPVisible(false)}
      >
        <div className="align-items-center justify-content-center">
          <div className="grid">
            <div className="col-3">
              <p className="mt-5 pt-2 flex justify-content-center flex-wrap">
                Productivity Rate =
              </p>
            </div>
            <div className="col-9">
              <p className="flex justify-content-center flex-wrap">
                <span style={{ color: "#F96363", fontWeight: "bold" }}>
                  Interaction Time
                </span>
                &nbsp;&nbsp; +&nbsp;&nbsp;{" "}
                <span style={{ color: "#419464", fontWeight: "bold" }}>
                  Other Tool Time
                </span>
                &nbsp;&nbsp; +&nbsp;&nbsp;{" "}
                <span style={{ color: "#E89C43", fontWeight: "bold" }}>
                  Approved Overhead
                </span>
              </p>
              <p
                className="flex justify-content-center flex-wrap pt-3 border-top-1"
                style={{ color: "#126BBD", fontWeight: "bold" }}
              >
                Employment Hours
              </p>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Work Rate"
        visible={wrvisible}
        style={{ width: "500px" }}
        onHide={() => setWRVisible(false)}
      >
        <div className="align-items-center justify-content-center">
          <div className="grid">
            <div className="col-3">
              <p className="mt-5 pt-2 flex justify-content-center flex-wrap">
                Work Rate =
              </p>
            </div>
            <div className="col-9">
              <p className="flex justify-content-center flex-wrap">
                <span style={{ color: "#F96363", fontWeight: "bold" }}>
                  Interaction Time
                </span>
                &nbsp;&nbsp; +&nbsp;&nbsp;{" "}
                <span style={{ color: "#419464", fontWeight: "bold" }}>
                  Other Tool Time
                </span>
              </p>
              <p className="flex justify-content-center flex-wrap pt-3 border-top-1">
                <span style={{ color: "#126BBD", fontWeight: "bold" }}>
                  Employment Time
                </span>
                &nbsp;&nbsp; -&nbsp;&nbsp;{" "}
                <span style={{ color: "#E89C43", fontWeight: "bold" }}>
                  Approved Overhead
                </span>
              </p>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6 pl-2">
        <Card
          className="chartCard "
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {per.options && (
            <ReactApexChart
              options={per.options}
              series={per.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {wp.options && (
            <ReactApexChart
              options={wp.options}
              series={wp.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {wir.options && (
            <ReactApexChart
              options={wir.options}
              series={wir.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {wbr.options && (
            <ReactApexChart
              options={wbr.options}
              series={wbr.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {pi.options && (
            <ReactApexChart
              options={pi.options}
              series={pi.series}
              type="bar"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {pi.options && (
            <ReactApexChart
              options={ptd.options}
              series={ptd.series}
              type="bar"
              height={300}
            />
          )}
        </Card>
      </div>

      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {time_vs_pannelsize.options && (
            <ReactApexChart
              options={time_vs_pannelsize.options}
              series={time_vs_pannelsize.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-6">
        <Card
          className="chartCard"
          style={{
            boxShadow: "-1px 2px 5px 0px #a7a2a2",
            height: "25rem",
            background: "#fff",
          }}
        >
          {wr.options && (
            <ReactApexChart
              options={wr.options}
              series={wr.series}
              type="line"
              height={300}
            />
          )}
        </Card>
      </div>
      {filtedata && filtedata.provider && filtedata.provider.length > 0 && (
        <div className="m-0 col-12 sm:col-12 md:col-6 lg:col-12  pl-2">
          <Card
            className="chartCard "
            style={{
              boxShadow: "-1px 2px 5px 0px #a7a2a2",
              height: "25rem",
              background: "#fff",
            }}
          >
            {timeComparison.options && (
              <ReactApexChart
                options={timeComparison.options}
                series={timeComparison.series}
                type="line"
                height={300}
              />
            )}
          </Card>
        </div>
      )}
      <div className="m-0 col-12 sm:col-12 ">
        <Tooltip target=".custom-target-icon" mouseTrack mouseTrackLeft={10} />
        {notes && notes.length > 0 && (
          <DataTable
            loading={nloading}
            filters={filters}
            filterDisplay="row"
            showGridlines
            stripedRows
            ref={dt}
            exportFilename={"notes_" + new Date().getTime()}
            value={notes}
            resizableColumns={true}
            removableSort
            paginator
            rows={200}
            rowsPerPageOptions={[100, 200, 500, 1000]}
            header={header}
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem", tableLayout: "fixed" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            className="financeDatatable"
          >
            <Column
              field="provider"
              header="Provider Id"
              filter
              filterPlaceholder="Search by Provider ID"
              sortable
            ></Column>
            <Column
              field="known_as"
              header="Provider Name"
              filter
              filterPlaceholder="Search by Provider Name"
              sortable
            ></Column>
            <Column
              field="patient"
              header="Patient"
              filter
              filterPlaceholder="Search by Patient"
              sortable
            ></Column>
            <Column
              field="reason"
              header="Reason"
              filter
              filterPlaceholder="Search by Reason"
              sortable
            ></Column>
            <Column
              field="context"
              header="Context"
              filter
              filterPlaceholder="Search by Context"
              sortable
            ></Column>
            <Column
              field="contents"
              className="custom-target-icon"
              header="Contents"
              style={{ width: "300px" }}
              filter
              filterPlaceholder="Search by Contents"
              sortable
            ></Column>

            <Column
              field="create_date"
              header="Created Date"
              sortable
              body={(e) => {
                return (
                  e.create_date &&
                  moment(e.create_date).format("MM/DD/YYYY HH:mm")
                );
              }}
            ></Column>
          </DataTable>
        )}

        {dashboard.productivity_csv && (
          <DataTable
            hidden
            showGridlines
            stripedRows
            ref={dt2}
            value={dashboard.productivity_csv}
            resizableColumns={true}
            removableSort
            paginator
            rows={200}
            rowsPerPageOptions={[100, 200, 500, 1000]}
            header={header}
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            className="financeDatatable"
          >
            {Object.keys(
              dashboard.productivity_csv.length > 0 &&
                dashboard.productivity_csv[0]
            ).map((col, index) => {
              return (
                <Column
                  align={index === 0 ? "left" : "right"}
                  field={col}
                  key={index}
                  body={(e) => {
                    return bodyTemplate(e, col);
                  }}
                  header={col === "Code" ? "CPT" : col}
                  sortable
                />
              );
            })}
          </DataTable>
        )}
        {dashboard.ptd_download && (
          <DataTable
            hidden
            showGridlines
            stripedRows
            ref={dt1}
            value={dashboard.ptd_download}
            resizableColumns={true}
            removableSort
            paginator
            rows={200}
            rowsPerPageOptions={[100, 200, 500, 1000]}
            header={header}
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            className="financeDatatable"
          >
            {Object.keys(
              dashboard.ptd_download.length > 0 && dashboard.ptd_download[0]
            ).map((col, index) => {
              return (
                <Column
                  align={index === 0 ? "left" : "right"}
                  field={col}
                  key={index}
                  body={(e) => {
                    return bodyTemplate(e, col);
                  }}
                  header={col === "Code" ? "CPT" : col}
                  sortable
                />
              );
            })}
          </DataTable>
        )}
      </div>
    </div>
  );
}

export default EmployeeChart;
