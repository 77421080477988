/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import GaugeChart from "react-gauge-chart";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import Chart from "react-apexcharts";
import { Chip } from 'primereact/chip';
import img from '../assets/no.png';
import KpiTrends from "./KpiTrends";
import {  exportCSV, exportExcel } from "../Common/Common";

//KPI and KPI details
export default function KpiNewPage({ details, keyId, setkeyId, Kpi, data, type, setdata, getDetails, title1, setTitle }) {
    const toast = useRef(null);
    const [isTrendsView, setIsTrendsView] = useState(false);
    const [kpiID, setkpiID] = useState(false);
    const [option] = useState({
        chart: {
            height: 350,
            type: "radialBar",
            // toolbar: {
            //   show: true
            // }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#fff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24,
                    },
                },
                track: {
                    background: "#fff",
                    strokeWidth: "67%",
                    margin: 0,
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35,
                    },
                },
                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: false,
                        color: "#111",
                        fontSize: "24px",
                    },
                    value: {
                        formatter: function (val) {
                            return val + "%";
                        },
                        color: "#111",
                        fontSize: "30px",
                        show: true,
                    },
                },
            },
        },
        fill: {
            colors: [
                function ({ value, seriesIndex, w }) {
                    return color(value);
                },
            ],

            type: ["solid", "gradient"],
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: ["#FF5733"],
                stops: [0, 100],
            },
        },
        stroke: {
            lineCap: "round",
        },
        labels: ["%"],
    });
    const color = (value) => {
        if (value < 25) {
            return "#ff3331";
        } else if (value >= 25 && value < 70) {
            return "#ffe431";
        } else {
            return "#11ec00";
        }
    }

    const dt = useRef(null);





    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between">
            <span className="text-xl text-900 font-bold">{title1}</span>
            <div>
                <Tooltip target="button" position="bottom" />
                {details && details.length > 0 && <>
                    <Button size="small" type="button" icon="pi pi-download" className="csv m-1" onClick={() => exportCSV(dt, false)} label="CSV" />
                    <Button
                        type="button"
                        size="small"
                        className="xls m-1"
                        icon="pi pi-download"

                        onClick={() => exportExcel("details", details)}
                        label="XLS"
                    /></>}
                <Button type="reset" size="small" icon="pi pi-arrow-left" style={{ height: '36px', }} className="xls m-1" label="Back" onClick={() => {
                    setkeyId(undefined);
                    setdata({ ...data, pid: 0 })
                }} />
            </div>
        </div>
    );
    
   
    const bodyTemplate= (data, col)=> {   return data[col]?data[col]:""}

    return (
        <>
            <div className="grid">
                <Toast ref={toast} />
                {isTrendsView && <KpiTrends keyId={kpiID} title1={title1} visible={isTrendsView} fdata={data} closeHandler={() => { setIsTrendsView(false) }}></KpiTrends>}

                {Kpi.length > 0 ? !keyId ? Kpi.map((x, i) => {
                    const val = x.kpi_value / 100;

                    return (
                        <div className="col-12 md:col-6 lg:col-4 " key={i}>
                            <Card header={<div className="flex flex-wrap align-items-center justify-content-between">
                                <span className="text-xl text-900 font-bold"></span>
                                <div>
                                    <Button type="reset" size="small" icon="pi pi-chart-line" style={{ height: '36px', }} className="xls m-1" onClick={(e) => { e.preventDefault(); setTitle(x.kpi_label); setkpiID(x.kpi_id); setIsTrendsView(true) }} />
                                </div>
                            </div>}
                                style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2" }}

                            >
                                <div className="m-0 " style={{ cursor: 'pointer' }} onClick={() => {
                                    setTitle(x.kpi_label);
                                    setkeyId(x.kpi_id);
                                    getDetails({ ...data, kpiId: x.kpi_id });
                                }}>
                                    {type === "p1" && <ProgressBar color={color(x.kpi_value)} className='mt-3 mb-6 mx-3' value={x.kpi_value}></ProgressBar>
                                    }
                                    {type === 'p' && (<Chart options={option} series={[x.kpi_value]} type="radialBar" />
                                    )}
                                    {type === 'c' && <h1 className="center">{x.kpi_value}%</h1>}
                                    {type === 'g' && (
                                        <GaugeChart
                                            textColor="red"
                                            className="center"
                                            arcsLength={[0.25, 0.45, 0.3]}
                                            colors={['#FF5733', '#FFFC33', '#42FF33']}
                                            id="gauge-chart2"
                                            percent={val}
                                        />
                                    )}
                                    <h4 className="center" style={{ height: '44px', marginBottom: '20px' }} tooltip={x.kpi_label}>{x.kpi_label}</h4>
                                </div>
                            </Card>
                        </div>
                    );
                }) : <div className="m-0 col-12">

                    <DataTable showGridlines stripedRows ref={dt} exportFilename={"details_export_" + new Date().getTime()} value={details} resizableColumns={true} removableSort paginator rows={200} rowsPerPageOptions={[100, 200, 500, 1000]} header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" className="financeDatatable">
                        {Object.keys(details.length > 0 && details[0]).map((col, index) => {

                            return <Column field={col} key={index} body={(e) => {
                                return bodyTemplate(e, col);
                            }} header={col} sortable />;

                        })}
                    </DataTable>
                </div> :
                    <div className="col-12  " >
                        <div className="card flex justify-content-center">
                            <Chip image={img} label="No Data" ></Chip>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}