import React, { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import logo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { jwtDecode } from "jwt-decode";

function Header({ setToken, token }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (token) {
      var dtoken = jwtDecode(token);
      setUser(dtoken);
    }
  }, [token]);

  const start = (
    <div className="flex align-items-center gap-2">
      <span style={{ display: "flex", justifyContent: "flex-start" }}>
        <img alt="logo" src={logo} height={50} width={90}></img>
      </span>
      {token && (
        <>
          {" "}
          <Button
            style={{
              padding: "1rem 1.25rem",
              width: "120px",
              border: "1px solid white",
              margin: "0px 10px",
              float: "right",
              background:
                (location.pathname === "/" || location.pathname === "/kpi") &&
                "#fff",
              color:
                (location.pathname === "/" || location.pathname === "/kpi") &&
                "#4393b9",
            }}
            label="KPI"
            onClick={() => {
              navigate("/kpi");
            }}
          />
          <Button
            style={{
              padding: "1rem 1.25rem",
              width: "120px",
              border: "1px solid white",
              margin: "0px 10px",
              float: "right",
              background: location.pathname === "/finance" && "#fff",
              color: location.pathname === "/finance" && "#4393b9",
            }}
            label="Finance"
            onClick={() => {
              navigate("/finance");
            }}
          />
          <Button
            style={{
              padding: "1rem 1.25rem",
              width: "120px",
              border: "1px solid white",
              margin: "0px 10px",
              float: "right",
              background: location.pathname === "/pmt" && "#fff",
              color: location.pathname === "/pmt" && "#4393b9",
            }}
            label="Management"
            onClick={() => {
              navigate("/pmt");
            }}
          />
        </>
      )}
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-2">
      {token && (
        <p
          className="text-lg font-medium text-white ml-3"
          style={{ width: "200px" }}
        >
          {location.pathname === "/finance"
            ? "Finance Dashboard"
            : location.pathname === "/" || location.pathname === "/kpi"
            ? "Performance Dashboard"
            : "Management Dashboard"}
        </p>
      )}
      {user && user.user && user.user.name && (
        <p
          className="text-lg font-medium text-white ml-3"
          style={{ width: "200px" }}
        >
          {user.user.name.givenName + " " + user.user.name.surname}
        </p>
      )}
      {token && (
        <Button
          style={{
            padding: "1rem 1.25rem",
            width: "100px",
            border: "1px solid white",
            margin: "0px 10px",
            float: "right",
          }}
          label="Log Out"
          onClick={() => {
            sessionStorage.clear();
            setToken(null);
            window.location.reload();
          }}
        />
      )}
    </div>
  );
  return (
    <div>
      <Menubar
        start={start}
        end={end}
        className="headerMenuBar"
        style={{ background: "#4393B9", width: "100%" }}
      />
    </div>
  );
}

export default Header;
