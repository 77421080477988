/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./../../src/theaming/theme.css"
import { Panel } from "primereact/panel";
import FinanceTable from "./FinanceTable";
import FilterComponent from "../Common/FilterComponent";
import { Toast } from "primereact/toast";
import axios from "axios";
import { Chip } from "primereact/chip";
import { ProgressSpinner } from "primereact/progressspinner";
import img from '../assets/no.png';


function NewFinanceSummary({ filtedata, setFilterData, toast, kpiSummary, closeHandler }) {
  const [finance, setFinance] = useState(null);
  const [loading, setLoading] = useState(false);

  const [active, setactive] = useState(kpiSummary ? kpiSummary : "Amount Expected")
  const methodVal = () => {
    if (active === "Amount Expected") return finance.amount_expected
    else if (active === "Amount Allowed") return finance.amount_allowed
    else if (active === "Amount Paid") return finance.amount_paid
    else if (active === "Amount Denied") return finance.amount_denied
    else return 0
  }
  const methodSummary = () => {
    if (active === "Amount Expected") return finance.amount_expected_list
    else if (active === "Amount Allowed") return finance.amount_allowed_list
    else if (active === "Amount Paid") return finance.amount_paid_list
    else if (active === "Amount Denied") return finance.amount_denied_list
    else return null
  }
  const FinanceCall = (data) => {
    setFinance(null);
    setLoading(true);

    axios.post(`${process.env.REACT_APP_API_KEY}collections`, data)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setFinance(response.data.data);
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {

      });
  }
  useEffect(() => {
    FinanceCall(filtedata)
  }, [])
  return (
    <div >
       <div className=" col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh" }}>
        <div className="m-0 col-12">
          <FilterComponent filtedata={filtedata} setFilterData={setFilterData} trends={"summary"} toast={Toast} getcollectionssummary={FinanceCall} />
        </div>
        {!loading ? finance && finance ?<>
        <div className="m-0 col-12">
          <div className="grid gap" >
            <Panel style={{ cursor: 'pointer', width: "13rem", textAlign: "center" }} onClick={() => { setactive("Amount Expected") }}

              className={`col p-0 align-items-center amount_expected newamount_expected ${active === "Amount Expected" && "activetab"}`}>
              <div className={`text-xl text-400 font-bold sCardText ${active === "Amount Expected" && "sActiveCardText"}`}>Amount Expected</div>

            </Panel>
            <Panel style={{ width: "13rem", cursor: 'pointer', textAlign: "center" }} onClick={() => { setactive("Amount Allowed") }}

              className={`col p-0 align-items-center justify-content-center amount_allowed  newamount_expected ${active === "Amount Allowed" && "activetab"}`}>
              <div className={`text-xl text-400 font-bold sCardText ${active === "Amount Allowed" && "sActiveCardText"}`} >Amount Allowed</div>

            </Panel>
            <Panel style={{ width: "13rem", cursor: 'pointer', textAlign: "center" }} onClick={() => { setactive("Amount Denied") }}
              className={`col p-0 align-items-center justify-content-center amount_denied  newamount_expected  ${active === "Amount Denied" && "activetab"}`}>
              <div className={`text-xl text-400 font-bold sCardText ${active === "Amount Denied" && "sActiveCardText"}`} >Amount Denied</div>

            </Panel>

            <Panel style={{ width: "13rem", cursor: 'pointer', textAlign: "center" }} onClick={() => { setactive("Amount Paid") }}
              className={`col p-0 align-items-center justify-content-center amount_paid  newamount_expected  ${active === "Amount Paid" && "activetab"}`}>
              <div className={`text-xl text-400 font-bold sCardText ${active === "Amount Paid" && "sActiveCardText"}`} >Amount Paid</div>

            </Panel>
          </div>
        </div>
        <div className="m-0 col-12">
          <FinanceTable closeHandler={closeHandler} title={active} val={methodVal()} summary={methodSummary() && methodSummary().map(x => {
            return { ...x, Total: (x.January + x.February + x.March + x.April + x.May + x.June + x.July + x.August + x.September + x.October + x.November + x.December) }
          })} />
        </div></>
     :
        <div className="card flex justify-content-center">
          <Chip image={img} label="No Data" ></Chip>
        </div> : <div className="card flex justify-content-center">
        <ProgressSpinner /></div>}  </div>
    </div>


  );
}
export default NewFinanceSummary;