import axios from 'axios'
import moment from 'moment'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { toDecimal } from '../Common/Common'
import FinanceCollectionsSummary from './FinanceCollectionsSummary'
import NewFinance from './NewFinance'
import NewFinanceSummary from './NewFinanceSummary'
import FinanceTrends from './FinanceTrends'
function FinanceDashboard() {
  const toast = useRef(null);
  const [screenName, setScreenName] = useState("dashboard")
  const [latestDate, setlatestDate] = useState()
  const [kpiSummary, setKpiSummary] = useState(null);
  const [finance, setFinance] = useState(null);
  const [isTrendsView, setIsTrendsView] = useState({ title: '', data: [], isopen: false, isNew: true });
  const [filtedata, setFilterData] = useState({
    organization_group: [],
    organization: [],
    cpt_code: [],
    cpt_category: [],
    primary: [],
    secondary: [],
    facility: [],
    years: [new Date().getFullYear().toString()],
    months: [],
    provider: [],
    state: [],
    patient:0
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}lastrefreshdate`)
      .then(async function (response) {
        if (response.data.success) {
          setlatestDate(response.data.data)
        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {
      })
  }, [])

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
        <Column align='right' footer={finance && toDecimal(finance.amount_expected, false)} />
        <Column align='right' footer={finance && toDecimal(finance.amount_denied, false)} />
        <Column align='right' footer={finance && toDecimal(finance.amount_allowed, false)} />
        <Column align='right' footer={finance && toDecimal(finance.amount_paid, false)} />
        <Column align='right' footer={finance && toDecimal(finance.patient_responsibility, false)} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className=" grid flex gap-3 pb-4">
      <div className=" col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh" ,padding:'0px'}}>
        <Toast ref={toast} />
        <div className={`flex justify-content-end mt-2 mr-2 `}>
          <p >Latest Refresh Date : <b>{moment(latestDate).format("dddd, DD MMMM, YYYY")}</b></p>
        </div>
        <div >
          {screenName === "dashboard" ? <NewFinance filtedata={filtedata} setFilterData={setFilterData} setKpiSummary={setKpiSummary} finance={finance} footerGroup={footerGroup} setFinance={setFinance} isTrendsView={isTrendsView} setIsTrendsView={setIsTrendsView} screenName={screenName} setScreenName={setScreenName} /> :
            screenName === "summarydetails" ? <FinanceCollectionsSummary filtedata={filtedata} setFilterData={setFilterData} setScreenName={setScreenName} toast={toast} footerGroup={footerGroup} /> :
            screenName === "financesummary" ? <NewFinanceSummary filtedata={filtedata} setFilterData={setFilterData} kpiSummary={kpiSummary} finance={finance} closeHandler={()=>setScreenName("dashboard")} />
            :
              <>
              <FinanceTrends filtedata={filtedata} setFilterData={setFilterData} title1={isTrendsView.title} fdata={isTrendsView.data} setScreenNam={setScreenName} visible={isTrendsView.isNew} closeHandler={() => {setIsTrendsView({ ...isTrendsView, isopen: false,data:[] }); setScreenName("dashboard")
         }}/> 
              </>}
        </div>
      </div>
    </div>
  )
}

export default FinanceDashboard