/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./../../src/theaming/theme.css"


function FilterComponent({ toast, getcollectionssummary, trends, filtedata, setFilterData }) {
    const [organization, setOrganization] = useState([]);
    const [primary, setPrimary] = useState([]);
    const [secondary, setSecondary] = useState([]);
    const [facility, setFacility] = useState([]);
    const [year, setYear] = useState([{name:'2023',id:'2023'},{name:'2024',id:'2024'}]);
    const [cpt, setCpt] = useState([]);
    const [codeCategory, setcodeCategory] = useState([]);
    const [category, setCategory] = useState([]);
    const [state, setState] = useState([]);
    const [provider, setProvider] = useState([]);
    const [month, setMonth] = useState([]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_KEY}financeDropdown`)
            .then(async function (response) {
                if (response.data.success) {
                    setOrganization(response.data.data.filter(x => x.type === "organization"))
                    setPrimary(response.data.data.filter(x => x.type === "primary"))
                    setSecondary(response.data.data.filter(x => x.type === "secondary"))
                    setFacility(response.data.data.filter(x => x.type === "facility"))
                    //setYear(response.data.data.filter(x => x.type === "year"))
                    //setCpt(response.data.data.filter(x => x.type === "cpt"))
                    const _cpts=response.data.data.filter(x => x.type === "code_category").map(x=>x.id);
                    setCpt([...new Set(_cpts)].map(x=>{
                        return {name:x}
                    }))
                    const _category=response.data.data.filter(x => x.type === "code_category").map(x=>x.name);
                    setCategory([...new Set(_category)].map(x=>{
                        return {name:x}
                    }));
                    setcodeCategory(response.data.data.filter(x => x.type === "code_category"))
                    setMonth(response.data.data.filter(x => x.type === "month"))
                    setState(response.data.data.filter(x => x.type === "rst"))
                    setProvider(response.data.data.filter(x => x.type === "provider"))
                } else {
                    toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
                }
            })
            .catch(function (error) {
                toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
            })
            .finally(function () {
            })
    }, []);

    const panelFooterTemplate = (data) => {
        const length = data ? data.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    //To display dropdown options 
    const DropdownOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">

                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="card" style={{ padding: "10px" }}>

            <div className=" grid flex py-3" style={{ padding: "0px", paddingLeft: "20px", paddingRight: "16px" }} >
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.organization_group.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal"> Organization Group</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.organization_group} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.organization_group)} onChange={(e) => {

                        const organization_group = e.value ? e.value : [];
                        let _organization = [];
                        organization_group && organization_group.forEach(y => {
                            if (y === 'Mass Retirees') {
                                _organization = [..._organization, ...organization.filter(x => x.name.includes('MIIA'))];
                                _organization = [..._organization, ...organization.filter(x => x.name.includes('Mass Retirees'))];
                                _organization = [..._organization, ...organization.filter(x => x.name.includes('Unicare'))]
                            } else {
                                _organization = [..._organization, ...organization.filter(x => x.name.includes(y))]
                            }

                        });
                        setFilterData({ ...filtedata, organization_group: organization_group, organization: _organization.map((item) => item.name) });
                    }} options={[{ name: 'MIIA', code: 'MIIA' }, { name: 'Mass Retirees', code: 'Mass Retirees' }, { name: 'Housing', code: 'Housing' }]} optionValue="name" optionLabel="name" placeholder="Organization Group" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.organization.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal">Organization</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect
                        maxSelectedLabels={2} value={filtedata.organization} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.organization)} onChange={(e) => { setFilterData({ ...filtedata, organization: e.value ? e.value : [] }) }} options={organization.sort((a, b) => {
                            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        })} optionValue="name" optionLabel="name" placeholder="Organization" filter className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.primary.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal" >Primary</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.primary} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.primary)} onChange={(e) => { setFilterData({ ...filtedata, primary: e.value ? e.value : [] }) }} options={primary.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionLabel="name" optionValue="name" placeholder="Primary" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.secondary.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal"  >Secondary</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.secondary} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.secondary)} onChange={(e) => { setFilterData({ ...filtedata, secondary: e.value ? e.value : [] }); }} options={secondary.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionValue="name" optionLabel="name" placeholder="Secondary" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.facility.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal">Facility</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.facility} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.facility)} onChange={(e) => { setFilterData({ ...filtedata, facility: e.value ? e.value : [] }); }} options={facility.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionValue="name" optionLabel="name" placeholder="Facility" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0  filterspaceing">
                    {filtedata.provider.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal"  >Provider</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.provider} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.provider)} onChange={(e) => { setFilterData({ ...filtedata, provider: e.value ? e.value : [] }); }} options={provider.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionValue="name" optionLabel="name" placeholder="Provider" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
            </div>
            <div className=" grid flex py-3" style={{ padding: "0px", paddingLeft: "20px", paddingRight: "16px" }} >
              

                <div className="col-2 p-0  filterspaceing">
                    {filtedata.state.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal">State</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.state} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.state)} onChange={(e) => { setFilterData({ ...filtedata, state: e.value ? e.value : [] }); }} options={state.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionValue="name" optionLabel="name" placeholder="State" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0 lg:flex-grow-0  filterspaceing">
                    {filtedata.cpt_category.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal" >Code Category</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.cpt_category} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.cpt_category)} onChange={(e) => { 
                            const _cpt_category = e.value ? e.value : [];
                            let _cpts = [];
                            _cpt_category && _cpt_category.forEach(y => {                               
                                _cpts = [..._cpts, ...codeCategory.filter(x=>x.name===y).map(x=>x.id)]                                
    
                            });
                        setFilterData({ ...filtedata,cpt_code:_cpts, cpt_category: _cpt_category }) 
                    }} options={category.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionLabel="name" optionValue="name" placeholder="Code Category" filter maxSelectedLabels={2} className="sm:w-full   mt-2 newmultiselectCss" />
                </div>
                <div className="col-2 p-0 lg:flex-grow-0  filterspaceing">
                    {filtedata.cpt_code.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal" >CPT</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                    <MultiSelect value={filtedata.cpt_code} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.cpt_code)} onChange={(e) => { setFilterData({ ...filtedata, cpt_code: e.value ? e.value : [] }) }} options={cpt.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })} optionLabel="name" optionValue="name" placeholder="CPT" filter maxSelectedLabels={2} className="sm:w-full   mt-2 newmultiselectCss" />
                </div>
                {trends !== "trends" && <>
                    <div className="col-2 p-0 lg:flex-grow-0 filterspaceing">
                        {filtedata.years.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal">Year</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                        <MultiSelect value={filtedata.years} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.years)} onChange={(e) => { setFilterData({ ...filtedata, years: e.value ? e.value : [] }); }} options={year} optionValue="name" optionLabel="name" placeholder="Year" filter maxSelectedLabels={2} className="sm:w-full  mt-2 newmultiselectCss" />
                    </div>
                    <div className="col-2 p-0 lg:flex-grow-0 filterspaceing">
                        {filtedata.months.length > 0 ? <label className="text-lg pl-1 text-700 block font-normal">Month</label> : <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>}
                        <MultiSelect value={filtedata.months} showClear resetFilterOnHide itemTemplate={DropdownOptionTemplate} panelFooterTemplate={() => panelFooterTemplate(filtedata.months)} onChange={(e) => { setFilterData({ ...filtedata, months: e.value ? e.value : [] }); }} options={month} optionValue="id" optionLabel="name" placeholder="Month" filter maxSelectedLabels={2} className="sm:w-full mt-2 newmultiselectCss" />
                    </div></>}
                <div className="col-2 p-0 lg:flex-grow-0 filterspaceing">
                    {filtedata.patient === 0 ? <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p> : <label className="text-lg pl-1 text-700 block font-normal">Patient</label>}
                    <InputNumber placeholder='Patient' useGrouping={false} className='sm:w-full mt-2 newmultiselectCss' value={filtedata.patient === 0 ? null : filtedata.patient} onChange={(e) => {  setFilterData({ ...filtedata, patient: e.value ? e.value : 0 }) }} />
                </div>
            </div>
            <div className="flex justify-content-end my-3">

                <div className="m-0 mx-3 ">
                    <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>
                    <Button type="reset" label="Reset" severity="secondary" className="w-full" onClick={() => {
                        let filterData = {
                            organization_group: [],
                            organization: [],
                            primary: [],
                            secondary: [],
                            facility: [],
                            years: [new Date().getFullYear().toString()],
                            cpt_code: [],
                            months: [],
                            provider: [],
                            state: [],
                            patient: 0,
                            cpt_category:[]
                        }
                        setFilterData(filterData);
                        getcollectionssummary(filterData);

                    }} />
                </div>
                <div className="m-0 mr-3">
                    <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>
                    <Button label="Apply" onClick={() => {
                        getcollectionssummary(filtedata)

                    }} className="w-full" />
                </div>
            </div>

        </div>

    )
}

export default FilterComponent