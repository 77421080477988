import axios from 'axios';
import { Row } from 'jspdf-autotable';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react'
import { exportCSV, exportExcel, toDecimal } from '../Common/Common';
import img from '../assets/no.png';
import FilterComponent from '../Common/FilterComponent';

function FinanceCollectionsSummary({ filtedata, setFilterData, setScreenName, toast, footerGroup }) {
    const dt = useRef(null);
    const [collectionssummary, setCollectionssummary] = useState([])
    const [loading, setLoading] = useState(false);


    const getcollectionssummary = (data) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_KEY}collectionssummary`, data)
            .then(function (response) {
                if (response.data.success) {
                    setLoading(false)
                    setCollectionssummary(response.data.data);
                } else {
                    toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
                }
            })
            .catch(function (error) {
                toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
            })
            .finally(function () {

            });
    }
    useEffect(() => {
        getcollectionssummary(filtedata)
    }, [])

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between">
            <span className="text-xl text-900 font-bold">Collection Summary</span>
            <div>
                <Tooltip target="button" position="bottom" />
                <>
                    <Button size="small" type="button" icon="pi pi-download" className="csv m-1" onClick={() => exportCSV(dt, false)} label="CSV" />
                    <Button
                        type="button"
                        size="small"
                        className="xls m-1"
                        icon="pi pi-download"

                        onClick={() => exportExcel('summary', collectionssummary)}
                        label="XLS"
                    />
                    <Button type="reset" size="small" icon="pi pi-arrow-left" style={{ height: '36px', }} className="xls m-1" label="Back" onClick={() => {
                        setScreenName("dashboard");
                    }} />
                </>

            </div>
        </div>
    );
    const bodyTemplate = (data, col) => { return data[col] ? data[col] : "" }


    return (
        <div className="col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh" }}>

            <div className="m-0 col-12">
                <FilterComponent filtedata={filtedata} setFilterData={setFilterData} trends={"summary"} toast={toast} getcollectionssummary={getcollectionssummary} />
            </div>
            {!loading ? collectionssummary.length > 0 ?
                <div className="m-0 col-12">
                    <DataTable showGridlines stripedRows ref={dt} exportFilename={"summary_export_" + new Date().getTime()} value={collectionssummary} footerColumnGroup={footerGroup} resizableColumns={true} removableSort paginator rows={200} rowsPerPageOptions={[100, 200, 500, 1000]} header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" className="financeDatatable" >
                        {Object.keys(collectionssummary[0]).map((col, index) => {
                            return <Column style={{ background: col === "Amount Expected" ? "#D9ECFE" : col === "Amount Denied" ? "#FCC9C9" : col === "Amount Allowed" ? "#F8CFA0" : col === "Amount Paid" ? "rgba(65, 153, 100, 0.39)":col ==="Patient Responsibility"&&"rgba(119, 164, 99, 0.47)" }} align={col.toLocaleLowerCase().includes("amount") ? 'right' : 'left'} field={col} key={index} body={(e) => {
                                return bodyTemplate(e, col);
                            }} header={col} sortable />;

                        })}
                    </DataTable>

                </div>
                :
                <div className="card flex justify-content-center">
                    <Chip image={img} label="No Data" ></Chip>
                </div> : <div className="card flex justify-content-center">
                <ProgressSpinner /></div>}
        </div>
    )
}

export default FinanceCollectionsSummary