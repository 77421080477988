import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

function Login({ setToken }) {
  const defaultValues = {
    password: "",
    username: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const toast = useRef();
  const onSubmit = (data) => {
    data.password = window.btoa(JSON.stringify(data.password));
    data.username = window.btoa(JSON.stringify(data.username));

    axios
      .post(`${process.env.REACT_APP_API_KEY}login`, data)

      .then(function (response) {
        if (response.data.success) {
          reset();
          sessionStorage.setItem("token", response.data.data);
          setToken(response.data.data);
          reset();
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.message,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data || error.response.data.message,
          life: 3000,
        });
      })
      .finally(function () {});
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  return (
    <div className="fullDiv">
      <div className="form-demo">
        <Toast ref={toast} />
        <div className="flex justify-content-center align-items-center">
          <div className="card">
            <h2 className="text-center">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="username"
                    control={control}
                    rules={{ required: "Name is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoFocus
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="username"
                    className={classNames({ "p-error": errors.name })}
                  >
                    Name*
                  </label>
                </span>
                {getFormErrorMessage("username")}
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        feedback={false}
                        id={field.name}
                        {...field}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password*
                  </label>
                </span>
                {getFormErrorMessage("password")}
              </div>

              <Button type="submit" label="Submit" className="mt-2" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
