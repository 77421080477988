/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react'
import { exportCSV, exportExcel } from '../Common/Common';
import { Toast } from 'primereact/toast';
import axios from "axios";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import img from '../assets/no.png';
import FilterComponent from '../Common/FilterComponent';

function FinanceTrends({filtedata, setFilterData, title1, closeHandler }) {
  const dt = useRef(null);
  const toast = useRef(null);

  const [loading, setLoading] = useState(true);
  const [trends, setTrends] = useState([]);

  useEffect(() => {
    gettrends(filtedata)
  }, [])
  const gettrends = (data) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_KEY}getFinanceTrendsDetails`, data)
      .then(function (response) {
        if (response.data.success) {
          setTrends(
            title1 === "OutstandingAging" ? response.data.data.outstanding_aging : title1 === "Yield Trend for last 6 months" ? response.data.data.ar_yield : title1 === "Disallow Trend for last 6 months" ? response.data.data.disallow_trend :title1 === "Amount Expected" ?response.data.data.amount_expected :response.data.data.ar_trend
          );
          setLoading(false)
        } else {
          setTrends([])
          setLoading(false);
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {
        setLoading(false);
      });

  }
 
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold amount_expected">{title1}</span>
      <div>
        <Tooltip target="button" position="bottom" />
        <Button size="small" type="button" icon="pi pi-download" className="csv m-1" onClick={() => exportCSV(dt, false)} label="CSV" />
        <Button
          type="button"
          size="small"
          className="xls m-1"
          icon="pi pi-download"

          onClick={() => exportExcel(title1, trends)}
          label="XLS"
        />
        <Button type="reset" size="small" icon="pi pi-arrow-left" style={{ height: '36px', }} className="xls m-1" label="Back" onClick={() => {
          setTrends([]); closeHandler();
        }} />

      </div>
    </div>
  );
  const bodyTemplate= (data, col)=> {   return data[col]?data[col]:""}

  return (
    <div>
      <Toast ref={toast} />

        
      <div className="col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh",padding:'0px' }}>
            <div className="m-0 col-12">
              <FilterComponent  filtedata={filtedata} setFilterData={setFilterData}  toast={Toast} trends={"trends"} getcollectionssummary={gettrends} />
            </div>
            {!loading ? trends.length>0 ?
            <div className="card m-0 col-12">

              <DataTable ref={dt} showGridlines stripedRows header={header} exportFilename={"details_export_" + new Date().getTime()} value={trends} resizableColumns={true} removableSort paginator rows={200} rowsPerPageOptions={[100, 200, 500, 1000]} scrollable scrollHeight="400px" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" tableStyle={{ minWidth: '50rem' }} className="financeDatatable">
                {trends && trends.length > 0 && Object.keys(trends[0]).map((col, index) => {
                  return <Column align={col.toLocaleLowerCase().includes("amount") || col.toLocaleLowerCase().includes("yield") ? 'right' : 'left'} field={col} key={index} body={(e) => {
                    return bodyTemplate(e, col);
                  }} header={col} sortable />;

                })}
              </DataTable>
            </div>: <div className="card flex justify-content-center">
            <Chip image={img} label="No Data" ></Chip>
          </div> : <div className="card flex justify-content-center">
          <ProgressSpinner /></div>}
            </div> 
      </div>

   
  )
}

export default FinanceTrends