import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./../../src/theaming/theme.css";
import axios from "axios";
import EmployeeCard from "./EmployeeCard";
import EmployeeChart from "./EmployeeChart";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [nloading, setNLoading] = useState(false);

  let [dashboard, setDashboard] = useState(null);
  let [notes, setNotes] = useState([]);
  const [qprovider, setQProvider] = useState([]);
  const [tier, setTier] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [billingcode, setBillingCode] = useState([]);
  const [provider, setProvider] = useState([]);
  const [dprovider, setDProvider] = useState([]);
  let [currentemployment, setCurrentEmployment] = useState([]);
  const [qualification, setQualification] = useState([]);
  const toast = useRef();
  const [filtedata, setFilterData] = useState({
    provider: [],
    qualification: undefined,
    start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end_date: undefined,
    billing_code: undefined,
    tier: undefined,
    employment_type: "Employed",
    organization: "Senscio Systems",
    rtype: "D",
  });
  const abortControllers = useRef(new Map()); // Map to hold controllers for each endpoint
  useEffect(() => {
    pmddropdown();
    pmtdashboard(filtedata);
    pmtnotes(filtedata);
    getEmployment();
    //getProviderTimeData(filtedata);
  }, []);
  const pmtdashboard = (values) => {
    setLoading(true);

    const endpoint = `${process.env.REACT_APP_API_KEY}pmtdashboard`;
    // Check if there's an ongoing request to this endpoint and abort it
    if (abortControllers.current.has(endpoint)) {
      abortControllers.current.get(endpoint).abort();
    }
    // Create a new AbortController instance
    const controller1 = new AbortController();
    abortControllers.current.set(endpoint, controller1);
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}pmtdashboard`,
        {
          ...values,
          start_date: new Date(values.start_date).toDateString(),
          end_date: values.end_date && new Date(values.end_date).toDateString(),
        },
        { signal: controller1.signal }
      )
      .then(function (response) {
        if (response.data.success) {
          setDashboard(response.data.data);
          setLoading(false);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        if (error.name === "CanceledError") {
          //console.log("Request canceled:", error.message);
        } else {
          toast?.current?.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.data,
            life: 3000,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProviderTimeData = (values) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}provider_time`, {
        ...values,
        start_date: new Date(values.start_date).toDateString(),
        end_date: values.end_date && new Date(values.end_date).toDateString(),
      })
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          //setDashboard(response.data.data);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const abortRequests = () => {
    abortControllers.current.forEach((controller) => {
      if (controller) {
        controller.abort();
      }
    });
    //console.log("All requests aborted");
  };

  useEffect(() => {
    // Cleanup function to abort all requests on component unmount
    return () => {
      abortRequests();
    };
  }, []);
  const pmtnotes = (values) => {
    setNLoading(true);
    const endpoint = `${process.env.REACT_APP_API_KEY}pmtnotes`;
    // Check if there's an ongoing request to this endpoint and abort it
    if (abortControllers.current.has(endpoint)) {
      abortControllers.current.get(endpoint).abort();
    }
    // Create a new AbortController instance
    const controller2 = new AbortController();
    abortControllers.current.set(endpoint, controller2);
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}pmtnotes`,
        {
          ...values,
          start_date: new Date(values.start_date).toDateString(),
          end_date: values.end_date && new Date(values.end_date).toDateString(),
        },
        { signal: controller2.signal }
      )
      .then(function (response) {
        setNLoading(false);
        if (response.data.success) {
          setNotes(response.data.data);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          //console.log("Request canceled:", error.message);
        } else {
          toast?.current?.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.data,
            life: 3000,
          });
        }
      })
      .finally(function () {
        setNLoading(false);
      });
  };
  const getEmployment = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}employment`)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setCurrentEmployment(response.data.currentdata);
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const pmddropdown = (values) => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}pmddropdown`, values)
      .then(function (response) {
        if (response.data.success) {
          setTier(response.data.data.filter((x) => x.description === "Tier"));
          setProvider(
            response.data.data.filter((x) => x.description === "provider")
          );
          setDProvider(
            response.data.data.filter((x) => x.description === "provider")
          );

          setBillingCode(
            response.data.data.filter((x) => x.description === "billing_code")
          );
          setQualification(
            response.data.data.filter((x) => x.description === "Qualification")
          );
          setOrganization(
            response.data.data.filter((x) => x.description === "organization")
          );
          setQProvider(response.data.dataprovider);
          setFilterData({
            provider: [],
            qualification: undefined,
            start_date: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ),
            end_date: undefined,
            billing_code: undefined,
            tier: undefined,
            employment_type: "Employed",
            organization: "Senscio Systems",
            rtype: "D",
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      })
      .catch(function (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      })
      .finally(function () {
        setLoading(false);
      });
  };
  const DropdownOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const selectedDropdownTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  useEffect(() => {
    ProviderFilter();
  }, [filtedata]);
  const ProviderFilter = () => {
    let data = [];
    if (filtedata.organization || filtedata.qualification) {
      data = qprovider;
      if (filtedata.organization)
        data = data.filter((x) => x.organization === filtedata.organization);
      if (filtedata.qualification)
        data = data.filter((x) => x.qualification === filtedata.qualification);
      if (filtedata.employment_type) {
        if (filtedata.employment_type === "Employed") {
          // data = data.filter((x) =>
          //   currentemployment.map((x) => x.provider).includes(x.provider)
          // );
          data = data.filter((item) => item.emp === filtedata.employment_type);
        } else if (filtedata.employment_type === "Houlry") {
          data = data.filter((item) => item.emp === filtedata.employment_type);
        } else {
          // data = data.filter(
          //   (x) =>
          //     !currentemployment.map((x) => x.provider).includes(x.provider)
          // );
          data = data.filter((item) => item.emp === filtedata.employment_type);
        }
      }

      data = data.map((x) => {
        return { value: x.provider, known_as: x.known_as };
      });
    } else {
      data = dprovider;
    }
    var resArr = [];
    data.filter(function (item) {
      var i = resArr.findIndex(
        (x) => x.known_as === item.known_as && x.provider === item.provider
      );
      if (i <= -1) {
        resArr.push(item);
      }
      return null;
    });
    setProvider(resArr);
  };
  const panelFooterTemplate = (data) => {
    const length = data ? data.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast} />

      <div
        className=" grid flex py-3"
        style={{ padding: "0px", paddingLeft: "20px", paddingRight: "16px" }}
      >
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Organization
          </label>
          <Dropdown
            value={filtedata.organization}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({
                ...filtedata,
                organization: e.value,
                provider: [],
              });
            }}
            options={organization
              .map((item) => {
                return { name: item.value, code: item.value };
              })
              .sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })}
            optionValue="code"
            optionLabel="name"
            placeholder="Select Organization"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Qualification
          </label>
          <Dropdown
            value={filtedata.qualification}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({
                ...filtedata,
                qualification: e.value,
                provider: [],
              });
            }}
            options={qualification
              .map((item) => {
                return { name: item.value, code: item.value };
              })
              .sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })}
            optionValue="code"
            optionLabel="name"
            placeholder="Select Qualification"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Employment Type
          </label>
          <Dropdown
            value={filtedata.employment_type}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({
                ...filtedata,
                employment_type: e.value,
                provider: [],
              });
            }}
            options={[
              { name: "Employed", code: "Employed" },
              { name: "Non-Employed", code: "Non-Employed" },
              { name: "Hourly-Employed", code: "Hourly" },
            ]}
            optionValue="code"
            optionLabel="name"
            placeholder=" Select Employment Type"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Provider
          </label>
          <MultiSelect
            value={filtedata.provider}
            showClear
            resetFilterOnHide
            itemTemplate={DropdownOptionTemplate}
            panelFooterTemplate={() => panelFooterTemplate(filtedata.provider)}
            onChange={(e) => {
              setFilterData({ ...filtedata, provider: e.value ? e.value : [] });
            }}
            options={provider
              .sort((a, b) => {
                const nameA = a.known_as.toUpperCase(); // ignore upper and lowercase
                const nameB = b.known_as.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })
              .map((item) => {
                return {
                  name: item.value + "-" + item.known_as,
                  code: item.value,
                };
              })}
            optionValue="code"
            optionLabel="name"
            placeholder="Provider"
            filter
            maxSelectedLabels={2}
            className="sm:w-full  mt-2 newmultiselectCss"
          />
        </div>

        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Billing Code
          </label>
          <Dropdown
            value={filtedata.billing_code}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({ ...filtedata, billing_code: e.value });
            }}
            options={billingcode
              .map((item) => {
                return { name: item.value, code: item.value };
              })
              .sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })}
            optionValue="code"
            optionLabel="name"
            placeholder="Select Billing Code"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Tier
          </label>
          <Dropdown
            value={filtedata.tier}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({ ...filtedata, tier: e.value });
            }}
            options={tier.map((item) => {
              return { name: item.value, code: item.value };
            })}
            optionValue="code"
            optionLabel="name"
            placeholder=" Select Tier"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Start Date
          </label>
          <Calendar
            placeholder="Start date"
            value={filtedata.start_date}
            onChange={(e) => {
              setFilterData({ ...filtedata, start_date: e.value });
            }}
            className="w-full mt-2 newmultiselectCss"
            showButtonBar
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            End Date
          </label>
          <Calendar
            placeholder="End date"
            value={filtedata.end_date}
            onChange={(e) => {
              setFilterData({ ...filtedata, end_date: e.value });
            }}
            className="w-full mt-2 newmultiselectCss"
            showButtonBar
          />
        </div>
        <div className="col-2 my-2 p-0  filterspaceing">
          <label className="text-lg pl-1 text-700 block font-normal">
            Report Type
          </label>
          <Dropdown
            value={filtedata.rtype}
            showFilterClear
            showClear
            onChange={(e) => {
              setFilterData({ ...filtedata, rtype: e.value });
            }}
            options={[
              { name: "Daily", code: "D" },
              { name: "Weekly ", code: "W" },
              { name: "Monthly", code: "M" },
            ]}
            optionValue="code"
            optionLabel="name"
            placeholder=" Select Tier"
            filter
            valueTemplate={selectedDropdownTemplate}
            itemTemplate={DropdownOptionTemplate}
            className="w-full mt-2 newmultiselectCss"
          />
        </div>
        <div className="col-12 p-0  filterspaceing"></div>
      </div>
      <div className="flex justify-content-end my-3">
        <div className="m-0 mx-3 ">
          <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>
          <Button
            type="reset"
            label="Reset"
            severity="secondary"
            className="w-full"
            onClick={() => {
              let filterData = {
                provider: [],
                qualification: undefined,
                start_date: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  1
                ),
                end_date: undefined,
                billing_code: undefined,
                tier: undefined,
                employment_type: "Employed",
                organization: "Senscio Systems",
                rtype: "D",
              };
              setFilterData(filterData);
              pmtdashboard(filterData);
              pmddropdown();
            }}
          />
        </div>
        <div className="m-0 mr-3">
          <p className="text-lg pl-2 text-700 font-normal"> {"  "}</p>
          <Button
            label="Apply"
            onClick={() => {
              pmtdashboard(filtedata);
              pmtnotes(filtedata);
            }}
            className="w-full"
          />
        </div>
      </div>
      {(loading || !dashboard) && (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      )}

      {dashboard && (
        <>
          <EmployeeCard loading={loading} cards={dashboard.cards} />
          <EmployeeChart
            filtedata={filtedata}
            loading={loading}
            nloading={nloading}
            dashboard={dashboard}
            notes={notes}
          />
        </>
      )}
    </>
  );
}
export default Dashboard;
