/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import axios from "axios";

import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import "./../../src/theaming/theme.css"
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { exportCSV, exportExcel, toDecimal } from "../Common/Common";
import { Chip } from "primereact/chip";
import img from '../assets/no.png';
import FinanceChat from "./FinanceChat";
import FilterComponent from '../Common/FilterComponent';
import WaterfallReport from "./WaterfallReport";

function NewFinance({ filtedata, setFilterData, setScreenName, isTrendsView, setIsTrendsView, setKpiSummary, finance, setFinance, footerGroup }) {
  const toast = useRef(null);
  const [trends, setTrends] = useState(null)
  const [aging, setAging] = useState([])
  const [aging1, setAging1] = useState([])
  const dt = useRef(null);
  const dta = useRef(null);

  const [loading, setLoading] = useState(false);
  const [waterfall, setWaterfall] = useState(false);
  const [summary, setSummary] = useState([]);
  const [rawcollections, setRawcollections] = useState([]);
  const [agingCSV, setAgingCSV] = useState([]);

  const getAgingReport = (data) => {
    axios.post(`${process.env.REACT_APP_API_KEY}agingReport`, data)
      .then(function (response) {
        if (response.data.success) {
          setAging(response.data.data);
          setAging1(response.data.dataexp);
          setAgingCSV(response.data.resultcsv);

        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {

      });

  }

  const getTrends = (data) => {
    axios.post(`${process.env.REACT_APP_API_KEY}financetrends`, data)
      .then(function (response) {
        if (response.data.success) {
          setTrends(response.data.data);


        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {

      });

  }
  const getRawCollection = (data) => {
    axios.post(`${process.env.REACT_APP_API_KEY}rawcollections`, data)
      .then(function (response) {
        if (response.data.success) {
          setRawcollections(response.data.data);


        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {

      });

  }
 
  const getCollection = (data) => {
    axios.post(`${process.env.REACT_APP_API_KEY}collections`, data)
      .then(function (response) {
        setLoading(false);
        if (response.data.success) {
          setFinance(response.data.data);
          setSummary(response.data.data.summary);

        } else {
          toast?.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.data, life: 3000 });
        }
      })
      .catch(function (error) {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });
      })
      .finally(function () {

      });

  }
  const FinanceCall = (data) => {
    setFinance(null);
    setLoading(true);
    setRawcollections([])
    getCollection(data);
    getTrends(data);
    getRawCollection(data);
    getAgingReport(data);

  }
  useEffect(() => {
    FinanceCall(filtedata)
  }, [])

  const bodyTemplate = (data, col) => { return data[col] ? data[col] : "" }

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">Collection Summary</span>
      <div>
        <Tooltip target="button" position="bottom" />
        <Button
          type="button"
          size="small"
          className="xls m-1"
          icon="pi pi-eye"
          onClick={() => { setScreenName("summarydetails") }}
          label="View"
        />
      </div>
    </div>
  );

  return (

    <div >
      <Toast ref={toast} />

      <div className="col-12  flex flex-column flex-shrink-0 " style={{ minHeight: "80vh",padding:'0px' }}>

        <div className="m-0 col-12">
          <FilterComponent filtedata={filtedata} setFilterData={setFilterData} trends={"summary"} toast={Toast} getcollectionssummary={FinanceCall} />
        </div>
        {!loading ? (finance ? <>  {rawcollections && rawcollections.length > 0 &&
          <div className="m-0 col-12">
            <div className="flex flex-wrap align-items-center justify-content-between">
              <span className="text-xl text-900 font-bold"></span>
              <div>
                <DataTable paginator  hidden={true} ref={dt} value={rawcollections} >
                  {Object.keys(rawcollections[0]).map((col, index) => {
                    return <Column field={col} key={index} />;

                  })}
                </DataTable>
                <DataTable paginator  hidden={true} ref={dta} value={agingCSV} >
                  {agingCSV.length>0&&Object.keys(agingCSV[0]).map((col, index) => {
                    return <Column field={col} key={index} />;

                  })}
                </DataTable>
                <Tooltip target="button" position="bottom" />
                <Button
                  type="button"
                  size="small"
                  className="xls m-1"
                  icon="pi pi-table"
                  onClick={() =>   setWaterfall(!waterfall)}
                  label={!waterfall?"Show Waterfall Report":"Hide Waterfall Report"}
                />
                <Button
                  type="button"
                  size="small"
                  className="xls m-1"
                  icon="pi pi-download"
                  onClick={() =>   dta.current.exportCSV({ selectionOnly:false })}
                  label="Aging CSV"
                />
                <Button
                  type="button"
                  size="small"
                  className="xls m-1"
                  icon="pi pi-download"
                  onClick={() => exportExcel('aging', agingCSV)}
                  label="Aging XLS"
                />
                <Button
                  type="button"
                  size="small"
                  className="xls m-1"
                  icon="pi pi-download"
                  onClick={() =>   dt.current.exportCSV({ selectionOnly:false })}
                  label="Collections CSV"
                />
                <Button
                  type="button"
                  size="small"
                  className="xls m-1"
                  icon="pi pi-download"
                  onClick={() => exportExcel('collections', rawcollections)}
                  label="Collections XLS"
                />
              </div>
            </div>
          </div>}
          {waterfall&&<div className="m-0 col-12">
            <WaterfallReport filtedata={filtedata} toast={toast}></WaterfallReport>
            </div>}
          <div className="m-0 col-12">
            <div className="grid gap" >
              <Tooltip position='bottom' target=".amount_expected" content={toDecimal(finance.amount_expected, false)} />

              <Panel style={{ cursor: 'pointer', textAlign: "center", width: "13rem", }} onClick={() => { setScreenName("financesummary"); setKpiSummary('Amount Expected') }}
                header={<></>}
                className="col align-items-center amount_expected panelCardHeader">
                <div className="text-xl text-400  cardText">Amount Expected</div>
                <span className="text-xl text-400   text-primary cardNumber"> {toDecimal(finance.amount_expected, true)}</span>
              </Panel>

              <Tooltip position='bottom' target=".amount_allowed" content={toDecimal(finance.amount_allowed, false)} />

              <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }} onClick={() => { setScreenName("financesummary"); setKpiSummary('Amount Allowed') }}
                header={<></>}
                className="col align-items-center justify-content-center amount_allowed newamount_allowed panelCardHeader">
                <div className="text-xl text-400  cardText" >Amount Allowed</div>
                <span className="text-xl text-400   text-primary cardNumber AACardNumber  text-yellow-500">{toDecimal(finance.amount_allowed, true)}</span>
              </Panel>

              <Tooltip position='bottom' target=".amount_denied" content={toDecimal(finance.amount_denied, false)} />

              <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }} onClick={() => { setScreenName("financesummary"); setKpiSummary('Amount Denied') }} header={<></>}
                className="col align-items-center justify-content-center amount_denied newamount_denied panelCardHeader">
                <div className="text-xl text-400  cardText" >Amount Denied</div>
                <span className="text-xl text-400   text-primary cardNumber ADCardNumber  text-red-500">{toDecimal(finance.amount_denied, true)}</span>
              </Panel>

              <Tooltip position='bottom' target=".amount_paid" content={toDecimal(finance.amount_paid, false)} />

              <Panel style={{ width: "13rem", textAlign: "center", cursor: 'pointer' }} onClick={() => { setScreenName("financesummary"); setKpiSummary('Amount Paid') }} header={<></>}
                className="col align-items-center justify-content-center amount_paid newamount_paid panelCardHeader">
                <div className="text-xl text-400  cardText" >Amount Paid</div>
                <span className="text-xl text-400   text-primary cardNumber APCardNumber  text-green-500">{toDecimal(finance.amount_paid, true)}</span>
              </Panel>

            </div>
          </div>
          <div className="m-0 col-12">
            {trends && aging && aging.length > 0 && <FinanceChat isTrendsView={isTrendsView} setScreenName={setScreenName} setIsTrendsView={setIsTrendsView} screen={"new"} finance={trends} aging1={aging1} aging={aging} />}
          </div>
          <div className="m-0 col-12">
            <Card style={{ boxShadow: "-1px 2px 5px 0px #a7a2a2" }}>

              {summary && summary.length > 0 ?
                <DataTable
                  showGridlines
                  stripedRows

                  value={summary}
                  footerColumnGroup={footerGroup}
                  resizableColumns={true}
                  removableSort
                  paginator={false}
                  rows={200}
                  rowsPerPageOptions={[100, 200, 500, 1000]}
                  header={header}
                  scrollable
                  scrollHeight="400px"
                  tableStyle={{ minWidth: '50rem' }}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  className="financeDatatable">
                  {Object.keys(summary[0]).map((col, index) => {
                    return <Column
                      style={{
                        background: col === "Amount Expected" ? "#D9ECFE" :
                          col === "Amount Denied" ? "#FCC9C9" :
                            col === "Amount Allowed" ? "#F8CFA0" :
                              col === "Amount Paid" ? "rgba(65, 153, 100, 0.39)" : col === "Patient Responsibility" && "rgba(119, 164, 99, 0.47)"
                      }}
                      align={col.toLocaleLowerCase().includes("amount") || col.toLocaleLowerCase().includes("responsibility") ? 'right' : 'left'}
                      field={col}
                      key={index}
                      body={(e) => {
                        return bodyTemplate(e, col);
                      }}
                      header={col}
                      sortable />;

                  })}
                </DataTable> :
                <div className="card flex justify-content-center">
                  <ProgressSpinner /></div>}</Card>
          </div>
        </> :
          <div className="card flex justify-content-center">
            <Chip image={img} label="No Data" ></Chip>
          </div>) : <div className="card flex justify-content-center">
          <ProgressSpinner /></div>}


      </div>
    </div>

  );
}
export default NewFinance;