import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import axios from "axios";

function ViewEmployment({ data, toast, closeHandler }) {
  const [formData, setFormData] = useState({
    provider: undefined,
    email: undefined,
    hours_per_week: undefined,
    start_date: undefined,
    mon_hr: 8,
    tue_hr: 8,
    wed_hr: 8,
    thu_hr: 8,
    fri_hr: 8,
    sat_hr: 0,
    sun_hr: 0,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: formData,
  });

  useEffect(() => {
    const viewEmployeeScheduleData = async (data) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}getEmploymentById`,
          data
        );
        if (response.data.success) {
          let info = response.data.data.work_schedule[0];
          setFormData({
            provider: info.provider,
            email: info.email,
            hours_per_week: info.hours_per_week,
            start_date: formatDate(new Date(info.start_date)),
            mon_hr: info.monday,
            tue_hr: info.tuesday,
            wed_hr: info.wednesday,
            thu_hr: info.thursday,
            fri_hr: info.friday,
            sat_hr: info.saturday,
            sun_hr: info.sunday,
          });
        } else {
          toast?.current.show({
            severity: "warn",
            summary: "Warning",
            detail: response.data.data,
            life: 3000,
          });
        }
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.data,
          life: 3000,
        });
      }
    };

    viewEmployeeScheduleData(data);
  }, [data, toast]);

  useEffect(() => {
    // Set form values when formData is updated
    for (const key in formData) {
      setValue(key, formData[key]);
    }
  }, [formData, setValue]);

  const onSubmit = (data) => {
    // Handle form submission
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className=" p-fluid">
        <div className="flex mt-5">
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="provider"
                control={control}
                rules={{ required: "Provider is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="text"
                    id={field.name}
                    {...field}
                    style={{
                      width: "500px",
                    }}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="provider"
                className={classNames({ "p-error": errors.provider })}
              >
                Provider *
              </label>
            </span>
            {getFormErrorMessage("provider")}
          </div>
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="text"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="email"
                className={classNames({ "p-error": errors.email })}
              >
                Email *
              </label>
            </span>
            {getFormErrorMessage("email")}
          </div>
        </div>
        <div className="flex mt-5">
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="hours_per_week"
                control={control}
                rules={{
                  required: "Hours Per Week is required.",
                  max: {
                    value: 40,
                    message: "Must be less than or equal to 40",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="hours_per_week"
                className={classNames({ "p-error": errors.hours_per_week })}
              >
                Hours Per Week *
              </label>
            </span>
            {getFormErrorMessage("hours_per_week")}
          </div>
          <div className="field m-1">
            <span className="p-float-label">
              <Controller
                name="start_date"
                control={control}
                rules={{ required: "Start Date is required." }}
                render={({ field, fieldState }) => (
                  //   <Calendar
                  //     dateFormat="mm/dd/yy"
                  //     id={field.name}
                  //     {...field}
                  //     style={{ width: "500px" }}
                  //     className={classNames({ "p-invalid": fieldState.invalid })}
                  //     readOnly
                  //   />
                  <InputText
                    type="text"
                    id={field.name}
                    {...field}
                    style={{ width: "500px" }}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="start_date"
                className={classNames({ "p-error": errors.start_date })}
              >
                Start Date *
              </label>
            </span>
            {getFormErrorMessage("start_date")}
          </div>
        </div>
        <label className="flex pt-5 pb-5">
          <b>Work Schedule</b>
        </label>
        <div className="grid">
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="mon_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    max={12}
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="mon_hr"
                className={classNames({ "p-error": errors.mon_hr })}
              >
                Monday
              </label>
            </span>
            {getFormErrorMessage("mon_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="tue_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="tue_hr"
                className={classNames({ "p-error": errors.tue_hr })}
              >
                Tuesday
              </label>
            </span>
            {getFormErrorMessage("tue_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="wed_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="wed_hr"
                className={classNames({ "p-error": errors.wed_hr })}
              >
                Wednesday
              </label>
            </span>
            {getFormErrorMessage("wed_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="thu_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="thu_hr"
                className={classNames({ "p-error": errors.thu_hr })}
              >
                Thursday
              </label>
            </span>
            {getFormErrorMessage("thu_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="fri_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="fri_hr"
                className={classNames({ "p-error": errors.fri_hr })}
              >
                Friday
              </label>
            </span>
            {getFormErrorMessage("fri_hr")}
          </div>
        </div>
        <div className="grid">
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="sat_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="sat_hr"
                className={classNames({ "p-error": errors.sat_hr })}
              >
                Saturday
              </label>
            </span>
            {getFormErrorMessage("sat_hr")}
          </div>
          <div className="field my-5 w-2/3 m-1">
            <span className="p-float-label">
              <Controller
                name="sun_hr"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    type="number"
                    id={field.name}
                    max={12}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    readOnly
                  />
                )}
              />
              <label
                htmlFor="sun_hr"
                className={classNames({ "p-error": errors.sun_hr })}
              >
                Sunday
              </label>
            </span>
            {getFormErrorMessage("sun_hr")}
          </div>
        </div>
        <div className="grid">
          <div className="col-4"></div>
          <div className="col-3">
            <Button
              type="button"
              label="Close"
              className="mt-2"
              onClick={closeHandler}
              outlined
            />
          </div>
          <div className="col-4"></div>
        </div>
      </form>
    </>
  );
}

export default ViewEmployment;
