import React, { useState } from "react";
import { Calendar } from "primereact/calendar"
import { Button } from "primereact/button";
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { FileUpload } from "primereact/fileupload";
function AddToolTime({ saveHandler, closeHandler,loading }) {
    const [file, setFile] = useState(undefined);

    const defaultValues = {
        date: undefined
    }
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
    const onSubmit = (data) => {
        if(file)
         saveHandler({...data,file:file.files[0]})
         }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">                 
                    <div className="field my-5">
                        <span className="p-float-label">
                            <Controller name="date" control={control} rules={{ required: 'Tool Time Date is required.' }} render={({ field, fieldState }) => (
                                <Calendar dateFormat="mm/dd/yy" id={field.name} {...field} for autoFocus style={{ width: '500px' }} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            <label htmlFor="date" className={classNames({ 'p-error': errors.name })}>Tool Time Date  *</label>
                        </span>
                        {getFormErrorMessage('date')}
                        <span className="mt-4 p-float-label">
                        <FileUpload
                          name="document"
                          uploadHandler={setFile}
                          url="/api/upload"
                          accept=".csv"
                          customUpload
                          auto={true}
                          mode="basic"
                        />
                        <p>{file ? file.files[0].name:<small className="p-error">Please Upload file</small>}</p>
                        </span>
                       
                    </div>
                    <div className="grid">
                        <div className="col-6">
                            <Button type="button" label="Cancel" className="mt-2" onClick={closeHandler} outlined />
                        </div>
                        <div className="col-6">
                            <Button loading={loading} type="submit" label="Submit" className="mt-2" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddToolTime