
const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      module.default.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};
module.exports = {
 isDate:function(sDate) {  
    if(sDate.toString().trim() === parseInt(sDate).toString()){
        return false;
    }  
    var tryDate = new Date(sDate);
    return (tryDate && tryDate.toString() !== "NaN" && tryDate !== "Invalid Date");  
  },
  exportExcel: function (title, summary) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(summary);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, title.trim());
    });
  },
  exportCSV: function (dt, selectionOnly) {
    dt.current.exportCSV({ selectionOnly });
  },

  toDecimal: function (val, isink) {
    return isink ? '$' + (val / 1000).toFixed(2) + ' K' : '$' + val.toFixed(2)
  },
}